import React from 'react';
import Card from 'libs/card/Card';
import { useNavigate } from 'react-router-dom';
import MotionDiv from 'libs/motionDiv';
import Button from 'libs/button/Button';
import FullPageLoader from 'libs/loader/FullPageLoader';
import icons from 'assets/index.ts';
import useTabTitle from 'libs/hooks/useTabTitle';
import { useSelector } from 'react-redux';
import { selectSelectedItem } from 'store/redux/navSlice';
import { useGetCountCustomerQuery } from './customer-management/customer-endpoints/customer.endpoints';
import { useGetVendorCountQuery } from './vendor-management/vendor-endpoints/vendor.endpoints';
import { hasAccess } from 'utils/hasAccess';

// Define types for CardDetail and CountData if needed
interface CardDetail {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  route: string;
  disabled: boolean;
}

// Define types for props if necessary
const Contact: React.FC = () => {
  const plant = useSelector(selectSelectedItem);
  const { data: CustomerCount, isLoading: isCustomerCountLoading } = useGetCountCustomerQuery({
    plant_id: plant?.id,
  });
  const navigate = useNavigate();
  useTabTitle('Contacts');
  const { data: CountData, isLoading: vendorsLoading } = useGetVendorCountQuery({ plantId: plant?.id });
  const customerAccess = hasAccess('con', { cus: 'v' });
  const vendorAccess = hasAccess('con', { ven: 'v' });

  // Define cardDetails with proper types
  const cardDetails: CardDetail[] = [
    customerAccess && {
      icon: <img src={icons.Customer} alt="" />,
      title: 'Customers',
      subtitle: `${CustomerCount?.customer_count ?? 0} Customers`,
      route: '/contacts/customer-management',
      disabled: false,
    },
    vendorAccess && {
      icon: <img src={icons.Vendor} alt="" />,
      title: 'Vendors',
      subtitle: `${CountData?.length > 0 ? CountData[0].count : 0} Vendors`,
      route: '/contacts/vendor-management',
      disabled: false,
    },
  ].filter(Boolean) as CardDetail[]; // Ensure proper type for filtered array

  return (
    <MotionDiv>
      <div className="h-full">
        <h1 className="h4-bold-black">Contacts</h1>

        <div className="relative h-full w-full">
          {isCustomerCountLoading || vendorsLoading ? (
            <div className="absolute left-64 top-16 flex items-center justify-center">
              <FullPageLoader />
            </div>
          ) : (
            <div className="grid h-full w-full grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {cardDetails.map((card, index) => (
                <div key={`${index}_${card.title}`}>
                  <Card
                    disabled={card.disabled}
                    title={card.title}
                    subtitle={card.subtitle}
                    leftIcon={card.icon}
                    rightCenterIcon={<Button color="primary">View</Button>}
                    onClick={() => navigate(card.route)}
                    titleStyle="font-medium text-gray-800"
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </MotionDiv>
  );
};

export default Contact;
