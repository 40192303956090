import { useMemo } from 'react'
import MotionDiv from 'libs/motionDiv'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { useGetInventoryQuery } from './inventory-endpoints/inventory.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { FormatTimeDifference } from 'utils/FormatTimeDifference'
import { NumberFormat } from 'utils/NumberFormat'
import FilterInventory from './panel/FilterInventory'
import useTabTitle from 'libs/hooks/useTabTitle'
import HeaderCard from 'libs/card/HeaderCard'
import ClickableRowArrow from 'assets/icons/camera-page/ClickableRowArrow'
import FilterableSearchTable from 'libs/table/filterable-search-table/FilterableSearchTable'
import { hasAccess } from 'utils/hasAccess'

const additionalFilters = {
  createdBetween: [],
  updatedBetween: [],
  quantityBetween: [],
  categories: [],
  sortBy: 'inventory_updated_on',
  sortDir: 'DESC',
}

const filters = {
  page_no: 1,
  page_size: 10,
  searchText: '',
  ...additionalFilters,
}

const Inventory = () => {
  useTabTitle(t('inventory_.inventory'))
  const inventoryAccess = hasAccess('inv', { inv: 'v' })
  const inventoryEditAccess = hasAccess('inv', { inv: 'e' })
  const navigate = useNavigate()
  const selectedItem = useSelector(selectSelectedItem)
  const { data } = useGetInventoryQuery(
    {
      plantId: selectedItem?.id,
      filters: filters,
    },
    {
      skip: !selectedItem?.id,
    },
  )

  const columns = useMemo(
    () => [
      {
        Header: <div className="flex  w-full items-center">{t('inventory_.product_name')}</div>,
        accessor: 'product',
        Cell: ({ row }: any) => {
          const data = row?.original
          return (
            <div
              className={`${inventoryAccess ? 'hyperlink' : ''}`}
              onClick={() => {
                inventoryAccess && navigate(`/inventory/transactionhistory/${data?._id}`)
              }}
            >
              {data?.name}
            </div>
          )
        },
        width: 250,
      },
      {
        Header: <div className="flex  w-full items-center">{t('inventory_.product_code')}</div>,
        accessor: 'code',
        width: 150,
        Cell: ({ row }: any) => {
          return <div>{row.original?.code}</div>
        },
      },
      {
        Header: <div className="flex w-full items-center justify-end">{t('inventory_.category')}</div>,
        accessor: 'category',
        width: 150,
        Cell: ({ row }: any) => {
          return <div>{row.original.category}</div>
        },
      },
      {
        Header: t('inventory_.quantity'),
        align: 'right',
        accessor: 'quantity',
        width: 100,
        Cell: ({ value }: any) => {
          return <div className="font-mono"> {NumberFormat(value)}</div>
        },
        disableSortBy: false,
      },
      {
        Header: t('inventory_.unit'),
        accessor: 'unit',
        width: 100,
        Cell: ({ row }: any) => {
          const unit = row.original?.unit_of_measure
          return <div>{unit ?? '-'}</div>
        },
      },
      {
        Header: <div className="flex items-center">{t('inventory_.last_updated')}</div>,
        accessor: 'updated_on',
        width: 250,
        Cell: ({ row }: any) => {
          const formattedDate = FormatTimeDifference(row.original?.inventory_updated_on)
          const userData = row.original?.inventory_updated_by
            ? row.original?.inventory_updated_by
            : row.original?.created_by
          const firstName = userData?.first_name
          const lastName = userData?.last_name
          const fullName = ` by ${firstName} ${lastName}`

          return (
            <div className="flex w-full justify-between">
              <span>
                {formattedDate}
                {fullName}
              </span>
              <span
                className="cursor-pointer"
                onClick={() => inventoryAccess &&  navigate(`/inventory/transactionhistory/${row?.original?._id}`)}
              >
                <ClickableRowArrow />
              </span>
            </div>
          )
        },
      },
    ],
    [],
  )
  const handleAddInventory = () => {
    inventoryEditAccess && navigate('/inventory/new')
  }

  const totalProducts = NumberFormat(data?.paging?.total_items_count)
  return (
    <MotionDiv>
      <>
        <div className="flex justify-between">
          <div>
            <h1 className="h4-bold-black ">{t('inventory_.details')}</h1>
            <div className="flex justify-between">
              <p className="w-[600px] p-sm-regular-1">{t('inventory_.details_desc')}</p>
            </div>
          </div>
          <HeaderCard
            value={totalProducts != null ? NumberFormat(totalProducts) : '-'}
            title={t('inventory_.total_products')}
            backgroundColor="#FFFFFF"
          />
        </div>
        <FilterableSearchTable
          useQuery={useGetInventoryQuery}
          columns={columns}
          placeholders={{
            emptyMessage: t('inventory_.no_inventory_added'),
            filterEmptyMessage: 'No matching results found',
            search: t('inventory_.inventory_search_placeholder'),
          }}
          addButton={{ label: t('inventory_.add_transactions'), onClick: handleAddInventory }}
          filterPanelComponent={FilterInventory}
          additionalFilters={additionalFilters}
          accessRights={inventoryEditAccess}
        />
      </>
    </MotionDiv>
  )
}

export default Inventory
