import Field from 'libs/field'
import WhiteCard from 'libs/card/WhiteCard'
import { useFormik } from 'formik'
import * as yup from 'yup'

import Button from 'libs/button/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import {
  useGetMaintenanceRequestByIDQuery,
  usePostMaintenanceRequestMutation,
  usePutMaintenanceRequestMutation,
} from '../maintenance-request/maintenance-request'
import { toast } from 'libs/toast'
import { useEffect } from 'react'
import FullPageLoader from 'libs/loader/FullPageLoader'

const AddEditMaintenanceRequest = () => {
  const navigate = useNavigate()
  const plantId = useSelector(selectSelectedItem)
  const ParamId = useParams()


  const [addMaintenanceRequest, { isLoading: addMrLoader }] = usePostMaintenanceRequestMutation()
  const [editMaintenanceRequest, { isLoading: editMrLoader }] = usePutMaintenanceRequestMutation()

  const { data: maintenanceRequestData, isLoading: idGetLoader } = useGetMaintenanceRequestByIDQuery(
    {
      plant_id: plantId?.id,
      mrID: ParamId?.id,
    },
    {
      skip: !plantId.id || !ParamId?.id,
    },
  )
 
  
  const formik = useFormik({
    initialValues: {
      requestID: '',
      reason: '',
      machine: '',
      description: '',
    },
    validationSchema: yup.object().shape({
      requestID: yup.string().required('RequestID is required'),
      reason: yup.string().required('Reason is required'),
      description: yup.string().required('Descripiton is required'),
    }),
    onSubmit: (values) => {
      const payload = {
        requestID: values.requestID,
        reason: values.reason,
        machine: values.machine,
        description: values.description,
      }
  
      if (!ParamId?.id){
      addMaintenanceRequest({ plant_id: plantId.id, data: payload })
        .unwrap()
        .then(() => {
          toast.success('The Request ' + payload.requestID + ' is added successfully'),
          navigate('/maintenance/maintenance-request')
          
        })
        .catch((error) => {
          const errorMessage = Array.isArray(error?.data?.detail) ? error?.data?.detail[0]?.msg : error?.data?.detail
          toast.error(errorMessage ?? 'Failed to add the Request ' + payload.requestID + ' Please try again.')
        })}
        else{
          editMaintenanceRequest({ plantId: plantId.id, data: payload,mrID:ParamId?.id })
          .unwrap()
          .then(() => {
            toast.success('The Request ' + payload.requestID + ' is updated successfully')
            navigate('/maintenance/maintenance-request')
          })
          .catch((error) => {
            const errorMessage = Array.isArray(error?.data?.detail) ? error?.data?.detail[0]?.msg : error?.data?.detail
            toast.error(errorMessage ?? 'Failed to edit the Request ' + payload.requestID + ' Please try again.')
          })}

        
    },
  })
  useEffect(() => {
    if (maintenanceRequestData && ParamId?.id) {
      formik.resetForm({
        values: {
          machine: maintenanceRequestData?.machine ? maintenanceRequestData?.machine : '',
          description: maintenanceRequestData?.description,
          reason: maintenanceRequestData?.reason,
          requestID: maintenanceRequestData?.requestID,
        },
      })
    }
  }, [maintenanceRequestData, ParamId?.id])

  return (<>{!idGetLoader?(
    <div className="flex flex-col gap-2">
      <h4 className="h4-bold-black">
        {' '}
      { !ParamId.id?' Create Maintenance Request':'Edit Maintenance Request'}
     
        {/* {vendorID && !isView ? 'Edit Maintenance Request' : isView ? vendorsData?.name : 'Create Maintenance Request'} */}
      </h4>
      <WhiteCard className="flex h-[75vh] flex-col justify-between">
        <div className="px-2">
          <h5 className="h5">Request Details</h5>
          <p className="p-sm-regular-1">Quickly report and add maintenance issues to ensure seamless operation.</p>
          <div className="grid grid-cols-2 gap-x-4  gap-y-2 px-2 sm:w-full lg:w-10/12">
            <Field
              type="text"
              required={true}
              placeholder="Enter Request ID"
              label="Request ID"
              formik={formik}
              name="requestID"
            />
            <Field type="text" placeholder="Enter Machines" label="Machines" formik={formik} name="machine" />
          </div>
          <div className="grid grid-cols-2 gap-x-4  gap-y-2 px-2 sm:w-full lg:w-10/12">
            <Field
              type="textarea"
              required={true}
              placeholder="Enter Reason"
              label="Reason"
              formik={formik}
              name="reason"
            />
            <Field
              type="textarea"
              required={true}
              placeholder="Enter Description"
              label="Descripiton"
              formik={formik}
              name="description"
            />
          </div>
        </div>

        <div className={`flex h-[4rem] items-center justify-end gap-2 bg-white px-6 `}>
          <Button onClick={() => navigate(-1)}>Cancel</Button>
          <Button color="success" loading={addMrLoader||editMrLoader} onClick={() => formik.handleSubmit()}>
          {!ParamId.id ? 'Submit' : 'Save'}
          </Button>
        </div>
      </WhiteCard>
    </div>):( <FullPageLoader/>)}</>
  )
}

export default AddEditMaintenanceRequest
