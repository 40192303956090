import Button from 'libs/button/Button'
import { toast } from 'libs/toast'
import { t } from 'i18next'

import { useDeleteTimesheetMutation } from '../timesheet-endpoints/timesheet.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'

interface Props {
  closePopUp: any
  batchData: string[]
  setBatchData: (data: string[]) => void
  deleteState: { state: boolean; ids: string[] } // Boolean to represent delete state
  setDeleteState: (state: { state: boolean; ids: string[] }) => void
}

const DeletePopUp = ({ closePopUp, batchData, setBatchData, deleteState, setDeleteState }: Props) => {
  const plant = useSelector(selectSelectedItem)
  const [deleteEntry, { isLoading: deleteEntryLoading }] = useDeleteTimesheetMutation()

  const handleDelete = () => {
    const ids = batchData?.length > 0 ? batchData : [deleteState.ids]

    deleteEntry({ plant_id: plant?._id, data: { data: ids } })
      .unwrap()
      .then(() => {
        closePopUp()
        setDeleteState({ state: false, ids: [] })
        setBatchData([])
        toast.success(`The timesheet record ${ids?.length} is removed successfully.`)
      })
      .catch((err) => {
        toast.error(err?.data?.detail ?? 'Something went wrong. Please try again')
      })
  }

  return (
    <>
      <p className="mb-3 p-xs-regular">
        {`Are you sure you want to delete ${batchData?.length > 0 ? batchData?.length : 1} entries? Once removed, these entries`}
        <span className="p-xs-bold"> cannot be recovered</span>
        <span>. However, you can create new entries for making the adjustments.</span>
      </p>

      <div className=" flex justify-end gap-3">
        <Button onClick={closePopUp}>{t('inventory_.close')}</Button>
        <Button color="error" onClick={handleDelete} loading={deleteEntryLoading}>
          {t('inventory_.delete')}
        </Button>
      </div>
    </>
  )
}

export default DeletePopUp
