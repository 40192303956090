import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import {
  useGetManufactureOrderByIdQuery,
  useGetManufactureOrderByQuantityProducedQuery,
  useGetManufactureOrderWoStatusQuery,
  useGetManufactureOrderProgressBarQuery,
} from '../manufacture-orders-endpoints/manufacture_order.endpoints'
import { getTableConfig } from '../columns/ManufactureOrderColumns'
import { useParams, useNavigate } from 'react-router-dom'
import WhiteCard from 'libs/card/WhiteCard'
import Button from 'libs/button/Button'
import MotionDiv from 'libs/motionDiv'
import FullPageLoader from 'libs/loader/FullPageLoader'
import Chip from 'libs/chip/Chip'
import { useEffect, useState } from 'react'
import Popup from 'libs/popup/Popup'
import DeleteMoPopup from '../popup/DeleteMoPopup'
import ProductTab from 'pages/productionV2/component/ProductTab'
import PaginatedTable from 'libs/table/PaginatedTable'
import useTabTitle from 'libs/hooks/useTabTitle'
import PieCharts from 'libs/charts/PieChart'
import CirclePieChart from 'libs/charts/CirclePieChart'
import CancelMoOrder from '../popup/CancelMoOrder'
import CompleteMoOrder from '../popup/CompleteMoOrder'
import StartMoOrder from '../popup/StartMoOrder'
import { WorkOrderChart } from 'pages/work-orders/work-orders-endpoints/workordersType'
import ProgressChart from 'libs/charts/ProgressChart'
import { hasAccess } from 'utils/hasAccess'

const ViewManufactureOrder = () => {
  const { id } = useParams()
  const { moTabs, purchaseOrdersColumns, salesOrdersColumns, workOrdersColumns } = getTableConfig()
  const plant = useSelector(selectSelectedItem)
  const manufactureOrderAccess = hasAccess('pla', { mo: 'e' })
  const [deletePopUp, setDeletePopUp] = useState<{ isPopUpOpen: boolean; moOrder: any }>({
    isPopUpOpen: false,
    moOrder: null,
  })
  const [cancelPopUp, setCancelPopUp] = useState<{ isPopUpOpen: boolean; moOrder: any }>({
    isPopUpOpen: false,
    moOrder: null,
  })
  const [startPopUp, setStartPopUp] = useState<{ isPopUpOpen: boolean; moOrder: any }>({
    isPopUpOpen: false,
    moOrder: null,
  })
  const [completePopUp, setCompletePopUp] = useState<{ isPopUpOpen: boolean; moOrder: any }>({
    isPopUpOpen: false,
    moOrder: null,
  })

  const navigate = useNavigate()

  useTabTitle('View Manufacturing Order')
  const [activeTab, setActiveTab] = useState(moTabs[0])

  const { data: MoOrder, isLoading: isMoOrderLoading } = useGetManufactureOrderByIdQuery(
    {
      plant_id: plant?.id,
      moID: id,
    },
    {
      skip: !plant?.id || !id,
    },
  )

  const { data: MoOrderQuantity } = useGetManufactureOrderByQuantityProducedQuery(
    {
      plant_id: plant?.id,
      moID: id,
    },
    {
      skip: !plant?.id || !id,
    },
  )
  const { data: MoOrderStatus } = useGetManufactureOrderWoStatusQuery(
    {
      plant_id: plant?.id,
      moID: id,
    },
    {
      skip: !plant?.id || !id,
    },
  )
  const { data: MoOrderProgressBar } = useGetManufactureOrderProgressBarQuery(
    {
      plant_id: plant?.id,
      moID: id,
    },
    {
      skip: !plant?.id || !id,
    },
  )

  // Filter data based on the active tab
  const filteredData = (name: string) => {
    switch (name) {
      case 'Sales Orders':
        return MoOrder?.salesOrders
      case 'Purchase Orders':
        return MoOrder?.purchaseOrders
      case 'Work Orders':
        return MoOrder?.workOrders
      default:
        return []
    }
  }

  // Determine columns based on active tab
  const columns = (name: string) => {
    switch (name) {
      case 'Sales Orders':
        return salesOrdersColumns
      case 'Purchase Orders':
        return purchaseOrdersColumns
      case 'Work Orders':
        return workOrdersColumns
      default:
        return []
    }
  }

  const columnsArray = columns(activeTab.name)
  const MoData = filteredData(activeTab.name)

  const handleCancelWorkOrder = () => {
    setCancelPopUp({ isPopUpOpen: true, moOrder: MoOrder })
  }
  const handleDeleteWorkOrder = () => {
    setDeletePopUp({ isPopUpOpen: true, moOrder: MoOrder })
  }
  const handleStartWorkOrder = () => {
    setStartPopUp({ isPopUpOpen: true, moOrder: MoOrder })
  }
  const handleCompleteWorkOrder = () => {
    setCompletePopUp({ isPopUpOpen: true, moOrder: MoOrder })
  }

  const quantityProduced = [
    {
      name: 'Completed',

      value:
        MoOrderQuantity?.TotalCompleteQuantity != null ? parseInt(MoOrderQuantity.TotalCompleteQuantity.toFixed(0)) : 0,
    },

    {
      name: 'Remaining',
      value:
        MoOrderQuantity?.TotalCompleteQuantity != null
          ? Math.max(0, 100 - parseInt(MoOrderQuantity.TotalCompleteQuantity.toFixed(0)))
          : 100,
    },
  ]
  const numberOfOperations: WorkOrderChart[] = [
    { name: 'Completed', value: MoOrderStatus?.Completed ?? 0 },
    { name: 'In Progress', value: MoOrderStatus?.Inprogress ?? 0 },
    { name: 'Remaining', value: MoOrderStatus?.Remaining ?? 0 },
  ]

  const [data, setData] = useState<any>([])

  useEffect(() => {
    if (MoOrderProgressBar?.progressData) {
      const updateExpectedProgress = (date: string, value: number) => {
        return MoOrderProgressBar?.progressData?.map((item: any) =>
          item?.date === date ? { ...item, expectedProgress: value } : item,
        )
      }
      const newData = updateExpectedProgress(MoOrderProgressBar?.expectedDate, 100)
      const formattedData = newData?.map((item: any) => ({
        ...item,
        actualProgress: item.actualProgress !== null ? parseFloat(item.actualProgress.toFixed(2)) : null,
        expectedProgress: item.expectedProgress !== null ? parseFloat(item.expectedProgress.toFixed(2)) : null,
      }))

      setData(formattedData)
    }
  }, [MoOrderProgressBar])

  return (
    <MotionDiv>
      {!isMoOrderLoading ? (
        <WhiteCard className=" flex flex-col gap-6">
          <div className="flex justify-between">
            <h5 className="h5">
              {MoOrder?.manufactureOrderID ? `${MoOrder.manufactureOrderID} Details` : 'No Order Details'}
            </h5>
            {manufactureOrderAccess && (
              <div className="flex gap-2">
                {MoOrder?.status === 'NOT_STARTED' && <Button onClick={handleCancelWorkOrder}>Cancel Mo</Button>}
                {MoOrder?.status === 'NOT_STARTED' && <Button onClick={handleStartWorkOrder}>Start</Button>}

                <Button
                  onClick={() => {
                    navigate(`/planning/mo/${id}/edit/basic-details`)
                  }}
                >
                  Edit
                </Button>

                {MoOrder?.status === 'NOT_STARTED' && <Button onClick={handleDeleteWorkOrder}>Delete</Button>}
                {MoOrder?.status === 'IN_PROGRESS' && (
                  <Button color="primary" onClick={handleCompleteWorkOrder}>
                    Mark as complete
                  </Button>
                )}
              </div>
            )}
          </div>

          <div className="mt-[-20px] flex items-center gap-2">
            <h1 className="border-r pr-2 text-center">{MoOrder?.finishedGood?.name}</h1>
            <Chip title={MoOrder?.status} />
          </div>
          <div className="my-4 flex gap-4">
            {/* Quantity Produced */}
            <div className=" min-w-[260px] flex-1 rounded-lg border-2 border-background-2 p-4">
              <h1 className="my-2 text-center text-[18px]">Quantity Produced</h1>
              <PieCharts apiData={quantityProduced} productionData={MoOrder} />
            </div>

            {/* Number of Operations */}
            <div className=" min-w-[380px] flex-1 rounded-lg border-2 border-background-2 p-4">
              <h1 className="my-2 text-center text-[18px]">Number of Work Orders</h1>
              <CirclePieChart apiData={numberOfOperations} title={'Total Work Orders'} />
            </div>

            {/* Work Order Progress */}
            <div className=" flex-2 rounded-lg border-2 border-background-2 p-4">
              <h1 className="my-2 text-center text-[18px]">Manufacturing Order Progress</h1>
              {MoOrderProgressBar?.progressData?.length > 0 ? (
                <ProgressChart data={data} highlightDate={MoOrderProgressBar?.expectedDate} />
              ) : (
                <>
                  <p className="m-auto mt-10 w-[300px] text-center p-sm-regular">
                    No start date or deadline provided. Add a start date and deadline to the Work Order to track your
                    progress effectively.
                  </p>
                  <div className="mt-5 flex items-center justify-center">
                    <Button
                      color="primary"
                      onClick={() => {
                        navigate(`/planning/mo/${id}/edit/basic-details`)
                      }}
                    >
                      Edit Mo
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="mt-2 flex flex-wrap gap-3">
            {moTabs.map((tab: any) => (
              <ProductTab
                key={tab._id}
                onClick={() => {
                  setActiveTab(tab)
                }}
                tabText={tab.name}
                isActive={activeTab.name === tab.name}
              />
            ))}
          </div>

          <PaginatedTable
            columns={columnsArray}
            rows={MoData}
            loading={isMoOrderLoading}
            emptyMessage="Data not found."
            hidePagination={true}
          />
          <Popup
            isOpen={cancelPopUp.isPopUpOpen}
            title={`Cancel: ${''}`}
            onClose={() => {
              setCancelPopUp({ isPopUpOpen: false, moOrder: null })
            }}
          >
            <CancelMoOrder
              closePopUp={() => setCancelPopUp({ isPopUpOpen: false, moOrder: null })}
              moOrder={cancelPopUp?.moOrder}
              plantId={plant?.id}
            />
          </Popup>
          <Popup
            isOpen={deletePopUp.isPopUpOpen}
            title={`Delete ${MoOrder?.manufactureOrderID}`}
            onClose={() => {
              setDeletePopUp({ isPopUpOpen: false, moOrder: null })
            }}
          >
            <DeleteMoPopup
              closePopUp={() => setDeletePopUp({ isPopUpOpen: false, moOrder: null })}
              data={deletePopUp?.moOrder}
            />
          </Popup>
          <Popup
            isOpen={startPopUp.isPopUpOpen}
            title={'Start Manufacturing Order'}
            onClose={() => {
              setStartPopUp({ isPopUpOpen: false, moOrder: null })
            }}
          >
            <StartMoOrder
              closePopUp={() => setStartPopUp({ isPopUpOpen: false, moOrder: null })}
              moOrder={startPopUp.moOrder}
              plantId={plant?.id}
            />
          </Popup>
          <Popup
            isOpen={completePopUp.isPopUpOpen}
            title={'Mark Complete?'}
            onClose={() => {
              setCompletePopUp({ isPopUpOpen: false, moOrder: null })
            }}
          >
            <CompleteMoOrder
              closePopUp={() => setCompletePopUp({ isPopUpOpen: false, moOrder: null })}
              moOrder={completePopUp.moOrder}
              plantId={plant?.id}
              quantityProduced={quantityProduced}
            />
          </Popup>
        </WhiteCard>
      ) : (
        <FullPageLoader />
      )}
    </MotionDiv>
  )
}

export default ViewManufactureOrder
