import Button from 'libs/button/Button'
import Chip from 'libs/chip/Chip'
import WhiteCard from 'libs/card/WhiteCard'
import MotionDiv from 'libs/motionDiv'
import PaginatedTable from 'libs/table/PaginatedTable'

import PlusIcon from 'assets/icons/camera-page/PlusIcon'
import { useEffect, useState } from 'react'

import { formatDate } from 'utils/unixConverter'

import QuickAddEditPurchaseOrder from 'libs/quick-add-edits/quick-add-edit-po/QuickAddEditPoPanel'
import { useNavigate } from 'react-router-dom'
import { hasAccess } from 'utils/hasAccess'

const ViewPurchaseOrders = ({ vendorsData, setPage, loading }: any) => {
  const Vendor = vendorsData
  const navigate = useNavigate()
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState<any>(null)
  const purchaseOrderAccess = hasAccess('ord', { po: 'e' })
  const purchaseOrderViewAccess = hasAccess('ord', { po: 'v' })
  useEffect(() => {
    setPage(3)
  }, [])

  const columns = [
    {
      Header: 'Purchase Order ID',
      accessor: 'purchaseOrderID',
      disableSortBy: true,
      width: 150,
      Cell: ({ row }: any) => {
        const vendor = row?.original
        return (
          <div
            className={`${purchaseOrderViewAccess ? 'hyperlink': ''}`}
            onClick={() => {
              purchaseOrderViewAccess && navigate(`/orders/purchase-order/view/${vendor?._id}`)
            }}
          >
            {vendor?.purchaseOrderID}
          </div>
        )
      },
    },
    {
      Header: 'Raw Materials',
      accessor: 'products',
      disableSortBy: true,
      width: 150,
      Cell: ({ row }: any) => {
        const product = row.original?.products?.length

        return (
          <div
            className={`${purchaseOrderViewAccess ? "hyperlink" : ''}`}
            onClick={() => {
              purchaseOrderViewAccess && navigate(`/orders/purchase-order/view/${row?.original?._id}`)
            }}
          >
            <span>{product} Raw Materials</span>
          </div>
        )
      },
    },

    {
      Header: <div className="flex w-full items-center justify-end">Arrival Date</div>,
      accessor: 'arrivalDate',
      disableSortBy: true,
      width: 100,
      Cell: ({ row }: any) => {
        const formattedDate = formatDate(row.original?.arrivalDate)

        return (
          <div className="flex w-full justify-between">
            <span>{formattedDate}</span>
          </div>
        )
      },
    },
    // {
    //   Header: <div className="flex w-full items-center justify-end">Progress</div>,
    //   accessor: 'status',
    //   width: 50,

    //   disableSortBy: true,
    // },
    {
      Header: <div className="flex w-full items-center justify-end">Progress</div>,
      accessor: 'status',
      width: 50,
      Cell: ({ row }: any) => <Chip title={row?.original?.status} />,
    },
  ]

  return (
    <MotionDiv>
      <WhiteCard className=" flex min-h-[70vh] flex-col gap-6">
        <div className="flex w-full justify-between">
          <h5 className="h5">Purchase Orders</h5>
          <div>
            {purchaseOrderAccess && (
              <Button
                startIcon={<PlusIcon />}
                color="primary"
                onClick={() => {
                  setIsPanelOpen(true)
                  setSelectedPurchaseOrder(null)
                }}
              >
                {'Add Purchase Order'}
              </Button>
            )}
          </div>
        </div>
        <QuickAddEditPurchaseOrder
          id={selectedPurchaseOrder?.purchaseOrderID?._id}
          onClose={() => setIsPanelOpen(false)}
          isOpen={isPanelOpen}
        />
        <div className="h-screen overflow-y-auto">
          <PaginatedTable
            columns={columns}
            loading={loading}
            rows={Vendor?.purchaseOrder}
            hidePagination={true}
            emptyMessage={'No purchase order found. Please add new purchase order'}
          />
        </div>
      </WhiteCard>
    </MotionDiv>
  )
}

export default ViewPurchaseOrders
