import { useState } from 'react'
import MotionDiv from 'libs/motionDiv'
import { useGetAllManufactureOrdersQuery } from './manufacture-orders-endpoints/manufacture_order.endpoints'
import MoFilterPanel from './panels/MoFilterPanel'
import { getTableConfig } from './columns/ManufactureOrderColumns'
import { useNavigate } from 'react-router-dom'
import DeleteMoPopup from './popup/DeleteMoPopup'
import Popup from 'libs/popup/Popup'
import useTabTitle from 'libs/hooks/useTabTitle'
import FilterableSearchTable from 'libs/table/filterable-search-table/FilterableSearchTable'
import { hasAccess } from 'utils/hasAccess'

const additionalFilters = {
  createdBetween: [],
  updatedBetween: [],
  endBetween: [],
  startBetween: [],
  status: undefined,
  finished_goods: [],
}

const ManufactureOrders = () => {
  useTabTitle('Manufacturing Orders')
  const navigate = useNavigate()
  const [moData, setMoData] = useState(null)
  const [isMoPopup, setIsMoPopup] = useState(false)
  const manufactureOrderEditAccess = hasAccess('pla', { mo: 'e' })

  const handleDeleteClick = (row: any) => {
    setMoData(row)
    setIsMoPopup(true)
  }

  const handleEditClick = (row: any) => {
    if (manufactureOrderEditAccess) navigate(`/planning/mo/${row._id}/edit/basic-details`)
  }

  const { columns } = getTableConfig(handleDeleteClick, handleEditClick)

  const handleAddManufactureOrder = () => {
    navigate('/planning/mo/add/basic-details')
  }

  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black ">{'Manufacturing Orders'}</h1>
        <p className="w-4/4 p-sm-regular-1">
          {'View the details of your added manufacturing orders and easily add new ones as needed.'}
        </p>
        <FilterableSearchTable
          useQuery={useGetAllManufactureOrdersQuery}
          addButton={{ label: 'Add Manufacturing Order', onClick: handleAddManufactureOrder }}
          accessRights={manufactureOrderEditAccess ?? false}
          columns={columns}
          placeholders={{
            search: 'Search Manufacturing Orders by ID, status',
            emptyMessage: 'No Manufacturing orders are added. Please add Manufacturing orders.',
            filterEmptyMessage: 'No matching results found',
          }}
          filterPanelComponent={MoFilterPanel}
          additionalFilters={additionalFilters}
        />
        <Popup
          isOpen={isMoPopup}
          title="Delete Manufacturing Order"
          onClose={() => {
            setIsMoPopup(false)
          }}
        >
          <DeleteMoPopup
            closePopUp={() => {
              setIsMoPopup(false)
            }}
            data={moData}
          />
        </Popup>
      </>
    </MotionDiv>
  )
}

export default ManufactureOrders
