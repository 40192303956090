import { useFormik } from 'formik'
import WhiteCard from 'libs/card/WhiteCard'
import Field from 'libs/field'
import MotionDiv from 'libs/motionDiv'
import AccessRights from './AccessRights'
import Button from 'libs/button/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { useUserInfoQuery } from '../../users/user-endpoints/user.endpoint'
import { usePostRolesMutation, usePutRolesPreferencesMutation } from '../roles-endpoints/roles.endpoint'
import { toast } from 'libs/toast'
import * as Yup from 'yup'
import useTabTitle from 'libs/hooks/useTabTitle'

const AddEditRoles = () => {
  const { data: userInfo } = useUserInfoQuery({})
  useTabTitle('User-Roles');
  const paramId = useParams()
  const [addUserRoles, { isLoading: isAddUserRolesLoading }] = usePostRolesMutation()
  const [updateUserRoles, { isLoading: isUpdateUserRolesLoading }] = usePutRolesPreferencesMutation()

  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      name: '',
      plants: [],
      modules: {
        das: {},
        pro: {},
        rev: {},
        pla: {},
        inv: {},
        ord: {},
        emp: {},
        con: {},
        tim: {}
      },
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('User role is required'),
      plants: Yup.array().min(1, 'Atleast one plant is required'),
    }),
    onSubmit: (values) => {
      // Transform the modules object
      const formattedModules = Object.keys(values.modules).reduce(
        (acc: Record<string, Record<string, string>>, moduleKey: string) => {
          const data = values.modules[moduleKey as keyof typeof values.modules]
          const moduleData = Array.isArray(data)
            ? data.map((item: { label?: string; [key: string]: any }) => ({
                ...item,
                label: item.label
                  ? item.label.includes(' ')
                    ? item.label
                        .split(' ')
                        .map((word: string) => word[0])
                        .join('')
                    : item.label.slice(0, 3)
                  : '',
              }))
            : []

          if (Array.isArray(moduleData) && moduleData.length > 0) {
            moduleData.forEach((item: { label: string; [key: string]: any }) => {
              // Find the permission type that is true
              const permissionType = Object.keys(item).find((key) => key !== 'label' && item[key])
              if (permissionType) {
                // Convert value to snake_case and add to the result object
                acc[moduleKey] = {
                  ...acc[moduleKey],
                  [item.label.replace(/\s+/g, '_').toLowerCase()]: permissionType[0],
                }
              }
            })
          }

          return acc
        },
        {},
      )

      // Prepare form values with formatted modules
      const formikSubmitValues = {
        name: values.name,
        plants: values.plants,
        modules: formattedModules,
      }

      // Handle API calls
      if (paramId?.id) {
        updateUserRoles({ data: formikSubmitValues, org_id: userInfo?.organization?._id, roleId: paramId?.id })
          .unwrap()
          .then((data) => {
            toast.success(`User Role ${data?.name} updated successfully`)
            navigate('/settings/user-management/roles')
          })
          .catch((error) => {
            toast.error(error?.data?.detail ?? 'Something went wrong. Please try again.')
          })
      } else {
        addUserRoles({ data: formikSubmitValues, org_id: userInfo?.organization?._id })
          .unwrap()
          .then((data) => {
            toast.success(`New User Role ${data?.name} created successfully`)
            navigate('/settings/user-management/roles')
          })
          .catch((error) => {
            toast.error(error?.data?.detail ?? 'Something went wrong. Please try again.')
          })
      }
    },
  })
  const plantOptions = userInfo?.plants?.map((plant: any) => {
    return {
      label: plant.name,
      value: plant._id,
    }
  })

  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black">{paramId?.id ? 'Edit User Roles' : 'Add User Roles'}</h1>
        <WhiteCard>
          <span className="px-2">
            <h5 className="h5">User Role Details</h5>
            <p className="p-sm-regular-1">
              {paramId?.id
                ? 'Edit the existing Sales Order details to make necessary changes and updates.'
                : 'Create a new user role with specific permissions'}
            </p>
          </span>
          <div className="grid grid-cols-2 gap-x-4  gap-y-2 px-2 sm:w-full lg:w-10/12">
            <Field
              type="text"
              required={true}
              placeholder="Enter User Role"
              label="User Role"
              formik={formik}
              name="name"
            />
            <Field
              type="multiselect"
              required={true}
              placeholder="select"
              label="Applicable Plants"
              formik={formik}
              name="plants"
              options={plantOptions}
            />
          </div>
          {/* Border */}
          <div className="my-3 border-[1px] bg-border sm:w-full lg:w-10/12"></div>
          {/* Access Rights */}
          <AccessRights formik={formik} />
          <div className={`flex h-[4rem] items-center justify-end gap-2 bg-white px-6`}>
            <Button onClick={() => navigate('/settings/user-management/roles')}>Cancel</Button>
            <Button
              loading={isAddUserRolesLoading || isUpdateUserRolesLoading}
              color="success"
              onClick={() => formik?.handleSubmit()}
            >
              {paramId?.id ? 'Save' : 'Add'}
            </Button>
          </div>
        </WhiteCard>
      </>
    </MotionDiv>
  )
}

export default AddEditRoles
