import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import MotionDiv from 'libs/motionDiv'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import EditableTable from 'libs/table/EditableTable'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import WhiteCard from 'libs/card/WhiteCard'
import { toast } from 'libs/toast'
import { useEffect, useState } from 'react'
import FullPageLoader from 'libs/loader/FullPageLoader'
import * as Yup from 'yup'
import Popup from 'libs/popup/Popup'
import SavedChangesPopup from 'pages/manufacture-orders/saved-changes-popup/SavedChangesPopup'
import useTabTitle from 'libs/hooks/useTabTitle'
import {
  useGetAllSalesOrdersQuery,
  usePostSalesOrderMutation,
  usePutSalesOrderMutation,
} from '../sales-order-endpoints/salesOrders.endpoints'

import { useGetCustomersQuery } from 'pages/contacts/customer-management/customer-endpoints/customer.endpoints'
import { useGetProductQuery } from 'pages/products/products-endpoints/products.endpoints'
import Panel from 'libs/panel'
import QuickAddEditCustomer from 'libs/quick-add-edits/quick-add-edit-customer/QuickAddEditCustomer'

const AddEditSalesOrder = () => {
  const plantId = useSelector(selectSelectedItem)
  const [savedChangesPopUp, setSavedChangesPopUp] = useState({ isPopUpOpen: false })
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const { id: paramsId } = useParams()
  useTabTitle(paramsId ? 'Update Sales Order' : 'Add Sales Order')
  const location = useLocation()
  // Access the state object
  const { from } = location.state || {}
  const navigate = useNavigate()
  const filters = {
    soID: paramsId,
    page_no: 1,
    page_size: 10,
    sortDir: 'DESC',
  }
  const { data: salesOrder, isLoading: SalesOrderLoading } = useGetAllSalesOrdersQuery(
    {
      plantId: plantId?.id,
      filters: filters,
    },
    {
      skip: !plantId?.id || !paramsId,
    },
  )
  const { data: customers } = useGetCustomersQuery(
    {
      plantId: plantId?.id,
      filters: filters,
    },
    { skip: !plantId?.id },
  )

  const [addSalesOrder, { isLoading: addSalesOrderLoading }] = usePostSalesOrderMutation()
  const [updateSalesOrder, { isLoading: updateSalesOrderLoading }] = usePutSalesOrderMutation()
  const salesOrderFormik = useFormik({
    initialValues: {
      salesOrderID: '',
      status: '',
      customerID: '',
      deliveryDate: null,
      description: '',
    },
    validationSchema: Yup.object().shape({
      salesOrderID: Yup.string().required('Sales Order ID is required'),
      status: Yup.string().required('Status is required'),
      customerID: Yup.string().required('Customer Name is required'),
      deliveryDate: Yup.string().required('Expected Delivery Date is required'),
    }),
    onSubmit: (values) => {
      const product =
        productsFormik?.values?.products
          ?.filter((product: any) => product?.product != null)
          .map((product: any) => ({
            product_id: product?.product?._id,
            quantity: product?.quantity,
          })) || []
      const payload = {
        customerID: values.customerID,
        salesOrderID: values.salesOrderID,
        deliveryDate: values.deliveryDate,
        status: values.status,
        description: values.description,
        products: product,
      }
      if (!paramsId) {
        addSalesOrder({ plant_id: plantId?.id, data: payload })
          .unwrap()
          .then(() => {
            toast.success('Sales order - ' + payload.salesOrderID + ' created successfully')
            if (from) {
              setSavedChangesPopUp({ isPopUpOpen: true })
            } else {
              navigate(-1)
            }
          })
          .catch((error) => {
            const errorMessage = Array.isArray(error?.data?.detail) ? error?.data?.detail[0]?.msg : error?.data?.detail
            toast.error(errorMessage ?? 'Something went wrong while creating the sales order')
          })
      } else {
        updateSalesOrder({ plant_id: plantId.id, salesOrderId: paramsId, data: payload })
          .unwrap()
          .then(() => {
            toast.success('Sales order - ' + payload.salesOrderID + ' updated successfully')
            if (from) {
              setSavedChangesPopUp({ isPopUpOpen: true })
            } else {
              navigate(-1)
            }
          })
          .catch((error) => {
            const errorMessage = Array.isArray(error?.data?.detail) ? error?.data?.detail[0]?.msg : error?.data?.detail
            toast.error(errorMessage ?? 'Something went wrong while updating the sales order')
          })
      }
    },
  })
  const productfilters = {
    page_no: 1,
    page_size: 1000,
    sortBy: 'updated_on',
    sortDir: 'DESC',
    searchText: '',
  }

  const { data: products, isLoading: productLoading } = useGetProductQuery(
    {
      plantId: plantId?.id,
      filters: productfilters,
    },
    {
      skip: !plantId?.id,
    },
  )

  const productOpitons = products?.data
    ?.filter((item: any) => item.category === 'Finished Goods')
    .map((item: any) => ({
      label: item.name,
      value: item,
    }))

  const newProductRow = {
    product: null,
    quantity: 0,
    unit_of_measure: null,
  }
  const productsFormik = useFormik<any>({
    initialValues: {
      products: [newProductRow],
    },
    onSubmit: () => {},
  })

  const productColumns = [
    {
      title: 'Products',
      flex: 2,
      content: {
        main: true,
        uniquePropertyInOption: 'name',
        options: productOpitons,
        name: 'product',
        placeholder: 'Select Product',
        type: 'select',
      },
    },
    {
      title: 'Quantity',
      align: 'right',
      hideRightBorder: true,
      content: {
        placeholder: '0',
        type: 'number',
        name: 'quantity',
      },
    },

    {
      title: 'UOM',
      flex: 1,
      content: {
        placeholder: 'Unit',
        type: 'text',
        name: 'unit_of_measure',
        readOnly: true,
      },
    },
  ]
  const customerDataOptions = customers?.data?.map((customer: any) => ({
    _id: customer._id,
    label: customer.name,
    sublabel: customer.primaryContactNumber,
    value: customer?._id,
  }))
  let verificatonOptions = [
    {
      label: 'In progress',
      value: 'IN_PROGRESS',
      _id: '101',
    },
    {
      label: 'Not started',
      value: 'NOT_STARTED',
      _id: '102',
    },
    {
      label: 'Completed',
      value: 'COMPLETED',
      _id: '104',
    },
    {
      label: 'Cancelled',
      value: 'CANCELLED',
      _id: '105',
    },
  ]
  if (!paramsId) {
    verificatonOptions = verificatonOptions.filter((option) => option.value != 'CANCELLED')
  }

  // updating sales order
  useEffect(() => {
    if (salesOrder) {
      const totalItemsCount = salesOrder?.paging?.total_items_count
      const dataLength = salesOrder?.data?.length
      if (totalItemsCount <= 0 || dataLength === 0) {
        toast.error('Something went wrong, Please try again.')
        return
      }
      const salesOrderData = salesOrder?.data?.[0]
      if (salesOrderData) {
        salesOrderFormik.resetForm({
          values: {
            salesOrderID: salesOrderData.salesOrderID || '',
            status: salesOrderData.status || '',
            deliveryDate: salesOrderData.deliveryDate || '',
            description: salesOrderData.description || '',
            customerID: salesOrderData.customer?._id || '',
          },
        })
        productsFormik.resetForm({
          values: {
            products: salesOrderData.products.map((product: any) => ({
              product: { _id: product?._id, name: product?.name },
              unit_of_measure: product?.unit_of_measure,
              quantity: typeof product?.quantity !== 'number' ? 0 : product?.quantity,
            })),
          },
        })
      }
    }
  }, [salesOrder])

  const openPanel = () => {
    setTimeout(() => {
      setIsPanelOpen(!isPanelOpen)
    }, 300)
  }
  

  return (
    <MotionDiv>
      <>
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <h1 className="h4-bold-black ">{paramsId ? 'Edit Sales Order' : 'Add Sales Order'}</h1>
          </div>
        </div>
        {SalesOrderLoading ? (
          <FullPageLoader />
        ) : (
          <WhiteCard className="mt-3 flex flex-col gap-6">
            <span>
              <h5 className="h5">Sales Order Details</h5>
              <p className="p-sm-regular-1">
                {paramsId
                  ? 'Edit the existing Sales Order details to make necessary changes and updates.'
                  : 'Add the basic details that are required to create new Sales Order.'}
              </p>
            </span>
            <div className=" flex flex-col gap-6">
              {/* --- process name and department */}
              <div className="grid  grid-cols-2 gap-x-4 gap-y-2 sm:w-full lg:w-10/12">
                <Field
                  type="text"
                  required={true}
                  label="Sales Order ID"
                  formik={salesOrderFormik}
                  name="salesOrderID"
                  placeholder="Enter Sales Order ID"
                />
                <Field
                  type="select"
                  required={true}
                  label="Status"
                  formik={salesOrderFormik}
                  options={verificatonOptions}
                  name="status"
                  defaultValue={verificatonOptions?.find(
                    (option) => option?.value === salesOrderFormik?.values?.status,
                  )}
                  placeholder="Enter Status"
                />
                <Field
                  type="select"
                  required={true}
                  label="Customer Name"
                  formik={salesOrderFormik}
                  name="customerID"
                  options={customerDataOptions}
                  defaultValue={customerDataOptions?.find(
                    (option) => option?._id === salesOrderFormik?.values?.customerID,
                  )}
                  placeholder="Enter Customer Name"
                  title={'Add Customer'}
                  onAdd={openPanel}
                />
                <Field
                  type="date"
                  required={true}
                  label="Expected Delivery Date"
                  formik={salesOrderFormik}
                  name="deliveryDate"
                  placeholder=''
                />
                <Field
                  type="text"
                  label="Description"
                  formik={salesOrderFormik}
                  name="description"
                  placeholder="Enter Description"
                />
              </div>

              {/* ---- border ----- */}
              <hr></hr>
              {/* ---- border ----- */}

              {/* editable - product */}
              <EditableTable
                formik={productsFormik as any}
                heading={'Products'}
                onSelect={(product: any, index: number) => {
                  productsFormik.setFieldValue(`products.${index}.unit_of_measure`, product.unit_of_measure)
                  productsFormik.setFieldValue(`products.${index}.quantity`, 0)
                }}
                loading={productLoading}
                columns={productColumns}
                emptyMessage="+ Click on the Add Product button to add a new product"
                newRow={newProductRow}
                name="products"
                addButtonTitle="Add Product"
              />
              <div className={`flex h-[4rem] items-center justify-end gap-2 bg-white px-6`}>
                <Button onClick={() => navigate(-1)}>Cancel</Button>
                <Button
                  color="success"
                  loading={addSalesOrderLoading || updateSalesOrderLoading}
                  onClick={() => salesOrderFormik.handleSubmit()}
                >
                  {paramsId ? 'Save' : 'Add'}
                </Button>
              </div>
            </div>
            {/* Saved Changes Popup */}
            <Popup
              isOpen={savedChangesPopUp.isPopUpOpen}
              title="Saved Changes"
              onClose={() => {
                setSavedChangesPopUp({ isPopUpOpen: false })
              }}
            >
              <SavedChangesPopup />
            </Popup>
            <Panel size="large" title="Add Vendor" isOpen={isPanelOpen} onClose={() => setIsPanelOpen(false)}>
              <QuickAddEditCustomer close={() => setIsPanelOpen(false)} />
            </Panel>
          </WhiteCard>
        )}
      </>
    </MotionDiv>
  )
}

export default AddEditSalesOrder
