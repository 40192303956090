import { useFormik } from 'formik'
import Field from 'libs/field'
import BarGraph from 'libs/charts/BarGraph'
import PieCharts from 'libs/charts/PieChart'
import Button from 'libs/button/Button'
import { useNavigate } from 'react-router-dom'
import ReportTable from './components/ReportTable'
import Loader from 'libs/loader/customloader/Loader'
import {
  useGetAllManufactureOrdersQuery,
  useGetManufactureOrdersBarStatsQuery,
  useGetManufactureOrdersOnTimeChartQuery,
} from 'pages/manufacture-orders/manufacture-orders-endpoints/manufacture_order.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import {
  usePutUserPreferencesMutation,
  useUserInfoQuery,
} from 'pages/settings/user-management/users/user-endpoints/user.endpoint'
import { useState } from 'react'
import { hasAccess } from 'utils/hasAccess'

const ManufactureOrderDashboard = () => {
  const { data, isLoading: userInfoLoading } = useUserInfoQuery({})
  const [updateUserPreference] = usePutUserPreferencesMutation()
  const manufactureOrderAccess = hasAccess('pla', { mo: 'e' })

  const navigate = useNavigate()
  const plantId = useSelector(selectSelectedItem)

  const initialValues = {
    dateRange: null,
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {},
  })

  const { data: manufactureStatusBar, isFetching: manufactureStatusBarLoading } = useGetManufactureOrdersBarStatsQuery(
    {
      plantId: plantId?.id,
      filters: formik.values,
    },
    {
      skip: !plantId?.id,
    },
  )
  const { data: manufactureOnTimeChart, isFetching: manufactureChartLoading } = useGetManufactureOrdersOnTimeChartQuery(
    {
      plantId: plantId?.id,
      filters: formik.values,
    },
    {
      skip: !plantId?.id,
    },
  )

  const { data: manufactureOrder } = useGetAllManufactureOrdersQuery(
    {
      plantId: plantId?.id,
      filters: { page_no: 1, page_size: 10 },
    },
    {
      skip: !plantId?.id,
    },
  )

  const [isBarGraphLoading, setIsBarGraphLoading] = useState(false)
  const [isPieChartLoading, setIsPieChartLoading] = useState(false)

  const manufactureOrderChart = [
    { name: 'On-Time', value: manufactureOnTimeChart?.onTime },
    { name: 'Delayed', value: 100 - manufactureOnTimeChart?.onTime },
  ]
  const saveWidgetOrder = async (widgetKey: string, setLoading: (loading: boolean) => void) => {
    try {
      setLoading(true)
      let updatedPreferences = { ...data?.preferences }
      if (!Array.isArray(updatedPreferences.chart)) {
        updatedPreferences.chart = [];
      }
      if (updatedPreferences?.chart?.includes(widgetKey)) {
        updatedPreferences.chart = updatedPreferences?.chart?.filter((value: string) => value !== widgetKey)
      }
      else {
        updatedPreferences.chart = [...updatedPreferences?.chart, widgetKey]
      }
      const payload = { pref: updatedPreferences }
      await updateUserPreference({ data: payload }).unwrap()
    } catch (error) {
      console.error('Failed to save widget order', error)
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    }
  }
  if (userInfoLoading) {
    return (
      <div className="flex h-screen items-center justify-center bg-gray-100">
        <Loader size="xl" color="primary" />
      </div>
    )
  }

  return (
    <>
      <div>
        {manufactureOrder && manufactureOrder?.paging?.total_items_count < 1 ? (
          <div className="flex h-screen items-center justify-center bg-gray-100">
            <div className="text-center">
              <p className="mb-4 text-xl">There is currently no data to display in the Manufacture Order Reports.</p>
              {manufactureOrderAccess && (
                <div className="flex justify-center ">
                  <Button
                    color="primary"
                    onClick={() => {
                      navigate('/planning/mo/add/basic-details')
                    }}
                  >
                    + Add Manufacture Order
                  </Button>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div className="flex items-center justify-between">
              <h1 className="p-2 text-2xl font-bold text-gray-900">Manufacture Order Reports</h1>
              <div className="m-2 w-48">
                <Field type="dateRange" formik={formik} name="dateRange" shouldDisableFutureDate={true} />
              </div>
            </div>
            <div className="flex h-full justify-between">
              {/* Table */}
              <div className="h-full w-7/12 overflow-auto py-2">
                <div className="h-full rounded-lg bg-white p-3 ">
                  <ReportTable formik={formik} />
                </div>
              </div>
              {/* Bar graph and Pie chart */}
              <div className="grid w-5/12  grid-cols-1 gap-2 p-2">
                <div className="rounded-lg bg-white p-2">
                  <div className="flex items-center justify-between px-2">
                    <h1 className="p-2 text-base font-bold">Manufacture Orders Status</h1>
                    <h1
                      className={`hyperlink  ${isBarGraphLoading ? 'pointer-events-none' : ''}`}
                      onClick={() => {
                        if (!isBarGraphLoading) {
                          saveWidgetOrder('manufactureOrderBarGraph', setIsBarGraphLoading)
                        }
                      }}
                    >
                      {data?.preferences && data.preferences?.chart?.includes('manufactureOrderBarGraph')
                        ? isBarGraphLoading
                          ? 'Loading...'
                          : 'Remove from dashboard'
                        : isBarGraphLoading
                          ? 'Loading...'
                          : 'Add to dashboard'}
                    </h1>
                  </div>
                  <div>
                    {manufactureStatusBarLoading ? (
                      <div className="flex h-full w-full items-center justify-center">
                        <Loader size="xl" color="primary" />
                      </div>
                    ) : (
                      <BarGraph formik={formik} apiData={manufactureStatusBar} />
                    )}
                  </div>
                </div>
                <div className="rounded-lg bg-white p-2 ">
                  <div className="flex items-center justify-between px-2">
                    <h1 className="p-2 text-base font-bold">On-Time Manufacture Orders</h1>
                    <h1
                      className={`hyperlink ${isPieChartLoading ? 'pointer-events-none' : ''}`}
                      onClick={() => {
                        if (!isPieChartLoading) {
                          saveWidgetOrder('manufactureOrderPieChart', setIsPieChartLoading)
                        }
                      }}
                    >
                      {data?.preferences && data.preferences?.chart?.includes('manufactureOrderPieChart')
                        ? isPieChartLoading
                          ? 'Loading...'
                          : 'Remove from dashboard'
                        : isPieChartLoading
                          ? 'Loading...'
                          : 'Add to dashboard'}
                    </h1>
                  </div>
                  <div>
                    {manufactureChartLoading ? (
                      <div className="flex h-full w-full items-center justify-center">
                        <Loader size="xl" color="primary" />
                      </div>
                    ) : (
                      <PieCharts apiData={manufactureOrderChart} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ManufactureOrderDashboard
