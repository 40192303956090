import PlusIcon from 'assets/icons/camera-page/PlusIcon'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import Column from './components/Column'
import ColumnWrapper from './components/ColumnWrapper'

type Column = {
  title: string
  accessor: string
  placeholder?: string
  fieldType?: string
  options?: { value: string | number; label?: string | number }[]
  editable?: boolean
  main?: boolean
  render?: (value: any) => any
  flex?: number
  align?: 'right' | 'left' | 'center' 
}

type EditableTableProps = {
  columns: Column[]
  defaultRows?: any[]
  hideActions?: boolean
  options?: { id: string | number; label: string; value: any }[]
  uniqueIdInOptions?: any
  onChange?: (values: any) => any
  onEdit?: (values: any) => any
  addButtonTitle?: string
  hideAddButton?: boolean
}

const action: Column = {
  title: 'Actions',
  accessor: 'action',
  flex: 1,
  align: 'left',
}
function removeEmptyObjects(arr: any[]) {
  const filteredArray = arr.filter((obj) => Object.keys(obj).length > 0)
  return filteredArray.length > 0 ? filteredArray : undefined
}

const NewEditableTable = ({
  columns,
  defaultRows,
  hideActions,
  hideAddButton,
  options,
  uniqueIdInOptions,
  onChange,
  onEdit,
  addButtonTitle,
}: EditableTableProps) => {
  // Options that are not selected
  
  const [remainingOptions, setRemainingOptions] = useState(options)
  

  // Corner radius for the first and last cell of the table
  const cornerRadius = '4px'

  // Number of columns for the grid
  const noOfColsForGrid = useMemo(() => {
    const totalFlex = columns?.reduce((acc, col) => {
      return acc + (col.flex ?? 1)
    }, 0)
    
    if (hideActions) return totalFlex
    return totalFlex + 1
  }, [columns])

  // Main accessor (if the editable table has a main column and controlled by options)
  const mainAccessor: any = useMemo(() => columns?.find((col) => col.main)?.accessor ?? {}, [columns])

  // Returns new record with null values for each column
  const getNewRecord = useCallback(() => {
    const newRecord: Record<string, any> = {
      id: Math.random(),
    }
    columns?.forEach((col) => {
      newRecord[col.accessor] = null
    })
    return newRecord
  }, [columns])

  // Add new record to the table
  const handleAdd = () => {
    formik.setFieldValue('formikRows', [...rows, getNewRecord()])
  }

  // Formik hook
  const formik = useFormik({
    initialValues: { formikRows: defaultRows && defaultRows?.length > 0 ? defaultRows : [getNewRecord()] },
    onSubmit: () => {},
  })

  // Rows
  const rows = useMemo(() => formik.values.formikRows ?? [], [formik.values.formikRows])

  // Set default rows if any
  useEffect(() => {
    if (Array.isArray(defaultRows)) {
      if (defaultRows.length !== 0) {
        formik.setFieldValue('formikRows', defaultRows)
      }
    }
  }, [defaultRows])

  // Return row with only editable columns (actual data that needs to be returned on submit)
  const getReturnableRow = (row: any) => {
    const _row: Record<string, any> = {}

    // Check if the row has an _id and add it to the returnable object
    if (row._id) {
      _row._id = row._id
    }

    Object.keys(row).forEach((key) => {
      // Skip null values and the 'id' key
      if (!(row[key] == null || key === 'id')) {
        // Get the list of editable columns
        const editableColumns = columns?.filter((col) => col.editable || col.main).map((col) => col.accessor)
        

        // Check if the current key is not in the list of editable columns
        const isEditableKey = !editableColumns.includes(key)

        // If it's an editable key, add it to the returnable object
        if (!isEditableKey) {
          _row[key] = row[key]
        }
      }
    })

    return _row
  }


  // On change, return only the editable
  useEffect(() => {
    const handleRowsChange = async () => {
      // If `onChange` is asynchronous, await its completion
      if (onChange) {
        await onChange(removeEmptyObjects(rows.map(getReturnableRow)))
      }
    }

    handleRowsChange()
  }, [rows])


  // Set remaining options (options that are not selected)
  useEffect(() => {
    const selectedOptions: any[] = []
    rows.forEach((row) => {
      const so = row?.[mainAccessor]?.[uniqueIdInOptions]
      if (so != null) {
        selectedOptions.push(row?.[mainAccessor]?.[uniqueIdInOptions])
      }
    })
    const _remainingOptions = options?.filter((option) => !selectedOptions.includes(option.id))
    setRemainingOptions(_remainingOptions)
  }, [columns, rows, options])

  // Update column values if options are updated
  useEffect(() => {
    if (options) {
      const newX = rows.map((row) => {
        let _row: any = {}
        Object.keys(row).forEach((key: any) => {
          if (key == mainAccessor) {
            const nx = options.find((op) => op?.id === row?.[key]?.[uniqueIdInOptions])
            _row[key] = nx?.value ?? row[key]
          } else {
            _row[key] = row[key]
          }
        })
        return _row
      })

      formik.resetForm({
        values: { formikRows: newX ?? rows },
      })
    }
  }, [options])

  const columnWidth = noOfColsForGrid <= 15 ? `${100 / noOfColsForGrid}%` : '130px'
  const styleObject = {
    display: 'grid',
    gridTemplateColumns: `repeat(${noOfColsForGrid}, ${columnWidth})`,
  }

  return (
    <div className="flex w-full flex-col gap-2 text-xs">
      <div className=" rounded-[4px] border">
        <div className="flex w-full min-w-[720px] flex-col rounded-md">
          {/* header - row */}

          <div style={styleObject} className={`font-normal text-white first:rounded-t-md`}>
            {Array.isArray(columns) &&
              [...columns, action].map((col) => {
                if (col.title === 'Actions' && hideActions) {
                  return null
                }
                return (
                  <div
                    key={col.title}
                    style={{
                      gridColumn: `span ${col?.flex ?? 1}`,
                    }}
                    className={`border-l border-l-[#75838D] bg-brand-primary-shade-3 px-2 py-2 first:rounded-bl-sm first:rounded-tl-md first:border-l-0 last:rounded-br-sm last:rounded-tr-md text-${col.align ?? 'left'}`}
                  >
                    <div>{col.title}</div>
                  </div>
                )
              })}
          </div>


            <>
              {Array.isArray(columns) &&
                rows?.map((row, rowIndex) => {
                  return (
                    <div key={row.id} style={styleObject} className={` font-normal`}>
                      {[...columns, action].map((column, colIndex) => {
                        return (
                          <ColumnWrapper
                            key={column.title}
                            column={column}
                            colIndex={colIndex}
                            columns={columns}
                            rowIndex={rowIndex}
                            row={row}
                            rows={rows}
                            formik={formik}
                            onEdit={onEdit}
                            options={options}
                            remainingOptions={remainingOptions}
                            getReturnableRow={getReturnableRow}
                            uniqueIdInOptions={uniqueIdInOptions}
                            mainAccessor={mainAccessor}
                            hideActions={hideActions}
                            cornerRadius={cornerRadius}
                          />
                        )
                      })}
                    </div>
                  )
                })}
            </>
        </div>
      </div>

      {/* add button */}
      {!hideAddButton && (
        <Button startIcon={<PlusIcon />} color="primary" onClick={handleAdd}>
          {addButtonTitle || 'Add'}
        </Button>
      )}
    </div>
  )
}

export default memo(NewEditableTable)
