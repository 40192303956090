import { getToken } from 'utils/CheckAuth'
import { rootReducer } from 'store/services/root.service'
import { buildUrl } from 'utils/BuildUrl'
import { GetUserRoleResponse } from './userRole'

export const roles = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query<GetUserRoleResponse, { org_id: string; filters: any }>({
      query: ({ org_id, filters }) => {
        const URL = buildUrl(`/org/${org_id}/user-roles`, filters)
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
        }
      },
      providesTags: ['RolesInfo'],
    }),
    postRoles: builder.mutation({
      query: ({ data, org_id }) => {
        return {
          url: `/org/${org_id}/user-role`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['RolesInfo'],
    }),
    putRolesPreferences: builder.mutation({
      query: ({ roleId, org_id, data }) => {
        return {
          url: `/org/${org_id}/user-role/${roleId}`,
          method: 'PUT',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['RolesInfo'],
    }),
    deleteRoles: builder.mutation({
      query: ({ org_id, roleId }) => {
        return {
          url: `/org/${org_id}/user-role/${roleId}`,
          method: 'DELETE',
          headers: getToken(),
        }
      },
      invalidatesTags: ['RolesInfo'],
    }),
    rolesStats: builder.query({
      query: ({ org_id }) => {
        return {
          url: `/org/${org_id}/user-role/count`,
          method: 'GET',
          headers: getToken(),
        }
      },
      providesTags: ['RolesInfo'],
    }),
    getByRoleId: builder.query({
      query: ({ org_id, roleId }) => {
        return {
          url: `/org/${org_id}/user-role/${roleId}`,
          method: 'GET',
          headers: getToken(),
        }
      },
      providesTags: ['RolesInfo'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetRolesQuery,
  useDeleteRolesMutation,
  useRolesStatsQuery,
  usePostRolesMutation,
  usePutRolesPreferencesMutation,
  useGetByRoleIdQuery,
} = roles
