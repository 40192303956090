import { useNavigate } from 'react-router-dom'
import { useGetProductionPlanQuery } from './production-plans-endpoints/productionPlan.endpoints'
import { formatDateWithoutYear, getDaysRemaining } from 'utils/unixConverter'
import { t } from 'i18next'
import MotionDiv from 'libs/motionDiv'
import FilterProductionPlan from './panel/FilterProductionPlan'
import useTabTitle from 'libs/hooks/useTabTitle'
import Chip from 'libs/chip/Chip'
import FilterableSearchTable from 'libs/table/filterable-search-table/FilterableSearchTable'
import { hasAccess } from 'utils/hasAccess'

const additionalFilters = {
  durationBetween: [],
  createdBetween: [],
  updatedBetween: [],
  status: [],
  sortBy: 'created_on',
  sortDir: 'DESC',
}

const ProductionPlans = () => {
  useTabTitle(t('plannings.production_plans'))
  const navigate = useNavigate()
  const productionPlansAccess = hasAccess('pla', { pp: 'v' })
  const productionPlansEditAccess = hasAccess('pla', { pp: 'e' })
  const columns = [
    {
      Header: 'Plan ID',
      accessor: 'planID',
      Cell: ({ row }: any) => {
        const data = row?.original
        return (
          <div className={`${productionPlansAccess ? 'hyperlink': ''}`} onClick={() => productionPlansAccess && navigate(`/planning/production-plans/view/${data?._id}`)}>
            {data?.planID}
          </div>
        )
      },
    },
    {
      Header: 'Manufacturing Order',
      accessor: 'manufactureOrders',
      Cell: ({ row }: any) => {
        const data = row?.original
        return (
          <div className={`${productionPlansAccess ? 'hyperlink': ''}`} onClick={() => productionPlansAccess && navigate(`/planning/production-plans/view/${data?._id}`)}>
            {data?.manufactureOrders?.filter((mo: any) => mo?._id).length} MOs
          </div>
        )
      },
      disableSortBy: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }: any) => {
        return (
          <>
            <Chip title={value} />
          </>
        )
      },
      disableSortBy: true,
    },
    {
      Header: 'Duration',
      accessor: 'scheduledDate',
      Cell: ({ row }: any) => {
        return (
          <>
            {row?.original?.scheduledDate ? (
              <div>
                <p className="p-xs-regular">
                  {formatDateWithoutYear(row?.original?.scheduledDate[0])} -{' '}
                  {formatDateWithoutYear(row?.original?.scheduledDate[1])}
                </p>
                <p className="p-xs-regular-dim">
                  {getDaysRemaining(row?.original?.scheduledDate[0], row?.original?.scheduledDate[1])} days
                </p>
              </div>
            ) : (
              <p className="ml-6">-</p>
            )}
          </>
        )
      },
      disableSortBy: true,
    },
  ]

  const handleAddProductionPlans = () => {
    productionPlansEditAccess && navigate('/planning/production-plans/add/plan-details')
  }

  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black">Production Plans</h1>
        <p className="p-sm-regular-1">View the details of your added Plans and easily add new ones as needed.</p>
        <FilterableSearchTable
          useQuery={useGetProductionPlanQuery}
          accessRights={productionPlansEditAccess ?? false}
          columns={columns}
          addButton={{ label: 'Add Plan', onClick: handleAddProductionPlans }}
          placeholders={{
            search: 'Search by Plan ID',
            emptyMessage: 'No data found, please add a production plans into the system.',
            filterEmptyMessage: 'No matching results found',
          }}
          filterPanelComponent={FilterProductionPlan}
          additionalFilters={additionalFilters}
        />
      </>
    </MotionDiv>
  )
}

export default ProductionPlans
