import { formatString } from 'utils/trimStringValues'

// Define the prop types
interface ChipProps {
  title: string | undefined // title is a required string
  value?: string // value is an optional string
  color?: string // color is an optional string with specific values
  type?: 'chip' | 'oval' // type is an optional string with specific values
  size?: 'md' | 'sm' // size is an optional string with specific values
  isFormatting?: boolean // is formatting optional
}

const Chip = ({ title, value, type = 'chip', size = 'md', isFormatting = true }: ChipProps) => {
  let className = '  '

  switch (title) {
    case 'PENDING':
      className += ' bg-warning-100 text-warning-900 '
      break
    case 'IN_PROGRESS':
    case 'MEDIUM':
    case 'PROCESSING':
      className += ' bg-warning-100 text-warning-900 '
      break
    case 'PROCUREMENT':
      className += ' bg-warning-100 text-warning-900 '
      break
    case 'CANCELLED':
    case 'REJECTED':
    case 'HIGH':
      className += 'bg-error-150 text-error-700'
      break
    case 'ADJUSTMENT':
      className += 'bg-error-150 text-error-800'
      break
    case 'DELAYED':
      className += 'bg-error-150 text-error-700'
      break
    case 'TRANSFER':
      className += 'bg-success-200  text-success-900'
      break
    case 'COMPLETED':
    case 'CLOSED':
    case 'LOW':
      className += 'bg-success-200  text-success-900'
      break
    case 'NOT_STARTED':
      className += 'bg-gray-200 text-gray-800'
      break
    case 'SHIPMENT':
      className += 'bg-primary-300 text-primary-800'
      break
    case 'TRANSFORMATION':
      className += 'bg-pink-100 text-pink-900'
      break
    case 'PRIMARY':
      className += 'bg-pink-100 text-pink-900'
      break
    case 'NOT_SCHEDULED':
      className += 'text-xs bg-[#E7F5FF] px-2 py-1 mr-1 rounded-full text-[#0B5ED7] whitespace-nowrap'
      break
    case 'APPROVED':
      className += 'bg-success-200  text-success-900'
      break
    case 'REJECTED':
     className += 'bg-error-150 text-error-700'
      break
    default:
      className += 'bg-primary-200 text-primary-800'
  }

  switch (type) {
    case 'oval':
      className += '  rounded-lg p-2 py-1'
      break
    case 'chip':
      className += ' rounded-3xl '
      break
  }

  switch (size) {
    case 'md':
      className += ' px-3 py-1 '
      break
    case 'sm':
      className += ' text-xs px-2 py-[2px] '
      break
  }

  return (
    <div className={`${className} `} style={{ width: type === 'chip' ? 'fit-content' : '' }}>
      {value != null && <h2 className="text-xs font-bold">{value}</h2>}
      <h1 className="truncate ">{isFormatting ? formatString(title) : title}</h1>
    </div>
  )
}

export default Chip
