import { getToken } from 'utils/CheckAuth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'
import { ExportWarehousesParams } from './warehouseType'
import { buildUrl } from 'utils/BuildUrl'

export const warehouse = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    postWarehouse: builder.mutation({
      query: ({ data, plant_id }) => {
        return {
          url: `/plant/${plant_id}/warehouse/`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['warehouseUpdate'],
    }),
    getWarehouse: builder.query({
      query: ({ plantId, filters }) => {
        const URL = buildUrl(`/plant/${plantId}/warehouse/`, filters)
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the warehouse ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['warehouseUpdate', 'inventoryUpdate', 'warehouseDelete'],
    }),
    getQuantityByWarehouseStroageAndProduct: builder.query({
      query: ({ plantId, warehouseId, storageAreaId, productId }) => {
        return {
          url: `/plant/${plantId}/warehouse/${warehouseId}/storage-area/${storageAreaId}/product/${productId}/quantity`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the quantity by warehouse, storage and product')
            }
            return response.json()
          },
        }
      },
      providesTags: ['warehouseUpdate'],
    }),
    getWarehouseById: builder.query({
      query: ({ plant_id, warehouse_id }) => {
        return {
          url: `/plant/${plant_id}/warehouse/${warehouse_id}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the warehouse')
            }
            return response.json()
          },
        }
      },
      providesTags: ['warehouseUpdate'],
    }),
    editWarehouse: builder.mutation({
      query: ({ data, plant_id, warehouse_id }) => ({
        url: `/plant/${plant_id}/warehouse/${warehouse_id}`,
        method: 'PUT',
        headers: getToken(),
        body: data,
      }),
      invalidatesTags: ['warehouseUpdate'],
    }),
    deleteWareHouse: builder.mutation({
      query: ({ plant_id, wareHouse_id }) => ({
        url: `/plant/${plant_id}/warehouse/${wareHouse_id}`,
        method: 'DELETE',
        headers: getToken(),
      }),
      invalidatesTags: ['warehouseDelete'],
    }),
    DeleteStorageArea: builder.mutation({
      query: ({ plant_id, wareHouse_id, storage_id }) => ({
        url: `/plant/${plant_id}/warehouse/${wareHouse_id}/storage-area/${storage_id}`,
        method: 'DELETE',
        headers: getToken(),
      }),
      invalidatesTags: ['warehouseUpdate'],
    }),
    postStorageArea: builder.mutation({
      query: ({ data, plant_id, wareHouse_id }) => {
        return {
          url: `/plant/${plant_id}/warehouse/${wareHouse_id}/storage-area`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['warehouseUpdate'],
    }),
    editStorage: builder.mutation({
      query: ({ data, plant_id, warehouse_id, storage_id }) => ({
        url: `/plant/${plant_id}/warehouse/${warehouse_id}/storage-area/${storage_id}`,
        method: 'PUT',
        headers: getToken(),
        body: data,
      }),
      invalidatesTags: ['warehouseUpdate'],
    }),
    getWarehouseAndStorageCount: builder.query({
      query: ({ plant_id }) => {
        return {
          url: `/plant/${plant_id}/warehouse/getTotal`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the  warehouse/storage count')
            }
            return response.json()
          },
        }
      },
      providesTags: ['warehouseUpdate'],
    }),
    exportWarehouses: builder.query<any, ExportWarehousesParams>({
      query: (params) => {
        return {
          url: `/plant/${params.plantId}/warehouse/export`,
          method: 'GET',
          headers: getToken(),
          params,
          responseHandler: async (response) => {
            const blob = await response.blob()
            const url = window.URL.createObjectURL(blob)
            return url
          },
        }
      },
    }),
  }),
  overrideExisting: false,
})
export const {
  usePostWarehouseMutation,
  usePostStorageAreaMutation,
  useGetWarehouseQuery,
  useGetWarehouseAndStorageCountQuery,
  useGetQuantityByWarehouseStroageAndProductQuery,
  useGetWarehouseByIdQuery,
  useEditWarehouseMutation,
  useEditStorageMutation,
  useDeleteWareHouseMutation,
  useDeleteStorageAreaMutation,
  useLazyExportWarehousesQuery,
} = warehouse
