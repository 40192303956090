import { useEffect, useMemo, useState } from 'react'
import MotionDiv from 'libs/motionDiv'
import { t } from 'i18next'
import Filter from 'assets/icons/camera-page/Filter'
import PlusIcon from 'assets/icons/camera-page/PlusIcon'
import { useNavigate } from 'react-router-dom'
import Button from 'libs/button/Button'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import PaginatedTable from 'libs/table/PaginatedTable'
import Panel from 'libs/panel'
import SearchBox from 'libs/searchbox/SearchBox'
import WhiteCard from 'libs/card/WhiteCard'
import { useDebouncedSearch } from 'libs/hooks/useSearch'
import useTabTitle from 'libs/hooks/useTabTitle'
import Pen from 'assets/icons/camera-page/Pen'
import Bin from 'assets/icons/camera-page/Bin'

import Popup from 'libs/popup/Popup'

import ClickableRowArrow from 'assets/icons/camera-page/ClickableRowArrow'
import { useGetVendorsQuery } from './vendor-endpoints/vendor.endpoints'

import FilterVendor from './panel/Vendorfilter'
import { filters } from './vendor-endpoints/vendorTypes'
import DeleteVendor from './delete-vendor/DeleteVendor'
import { formatString } from 'utils/trimStringValues'
import { countryPhoneNumber } from 'utils/phoneNumberValidator'
import { hasAccess } from 'utils/hasAccess'

const VendorManagement = () => {
  useTabTitle('Vendor Management')
  const navigate = useNavigate()
  const plant = useSelector(selectSelectedItem)
  const [key, setKey] = useState(0)
  const { searchValue, handleSearch, inputValue } = useDebouncedSearch()
  const [deletePopUp, setDeletePopUp] = useState({ isPopUpOpen: false, VendorData: null })
  const vendorAccess = hasAccess('con', { ven: 'v' })
  const vendorEditAccess = hasAccess('con', { ven: 'e' })

  const [showFilter, setShowFilter] = useState(false)
  const [filters, setFilters] = useState<filters>({
    page_no: 1,
    page_size: 10,
    searchText: '',
    sortBy: 'updated_on',
    sortDir: 'DESC',
    vendorType: [],
  })
  const activeFiltersCount = [filters?.vendorType]?.filter((filter: any) => filter?.length > 0)?.length

  const {
    data: vendorsData,
    isLoading: isVendorsLoading,
    isFetching: isVendorsFetching,
  } = useGetVendorsQuery(
    { plantId: plant?.id, filters },
    {
      skip: !plant?.id,
    },
  )

  // handle sort for column
  const handleSort = (columnName: any) => {
    setFilters((prevFilters: any) => {
      // Determine the new sort direction
      const newSortDir = prevFilters?.sortBy === columnName ? (prevFilters?.sortDir === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
      // Update the filters state
      return {
        ...prevFilters,
        sortBy: columnName,
        sortDir: newSortDir,
      }
    })
  }

  const handleFilterToggle = () => {
    setShowFilter(!showFilter)
  }

  const handlePageChange = (page_no: any) => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      page_no: page_no,
    }))
    setKey((prevKey) => prevKey + 1)
  }

  // const handleWorkOrdersSelection = (workOrder: any) => {
  //     navigate(`view/${workOrder?._id}`)
  // }

  const handlePageNumberChange = (newPageSize: any) => {
    const totalItemsCount = vendorsData?.paging?.total_items_count
    const currentPage = filters.page_no ?? 1
    const startIndex = (currentPage - 1) * filters.page_size + 1
    const totalPages = totalItemsCount ? Math.ceil(totalItemsCount / newPageSize) : 0
    let newPageNo = Math.ceil(startIndex / newPageSize)
    newPageNo = Math.min(newPageNo, totalPages)
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      page_no: newPageNo,
      page_size: newPageSize,
    }))
    setKey((prevKey) => prevKey + 1)
  }

  //search
  useEffect(() => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      page_no: 1,
      searchText: searchValue ? searchValue : '',
    }))
  }, [searchValue])

  const columns = useMemo(
    () => [
      {
        Header: 'Vendor Name',
        accessor: 'name',
        Cell: ({ row }: any) => {
          const vendor = row?.original
          return (
            <div
              className={`${vendorAccess ? 'hyperlink': ''}`}
              onClick={() => {
                vendorAccess &&navigate(`/contacts/vendor-management/${vendor?._id}/view-basic-details`)
              }}
            >
              {vendor?.name}
            </div>
          )
        },

        // Cell: ({ value }: any) => <div className="link-text">{value}</div>,
      },
      {
        Header: 'Vendor Type',
        accessor: 'vendorType',
        Cell: ({ value }: any) => formatString(value),
      },

      {
        Header: 'Category',
        accessor: 'category',
        Cell: ({ value }: any) =>
          value
            ?.replace(/_/g, ' ')
            .toLowerCase()
            .split(' ')
            .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' '),
      },

      {
        Header: 'Contact Number',
        accessor: 'primaryContactNumber',
        Cell: ({ value }: any) => {
          const { phoneNumber } = countryPhoneNumber(value)

          return <span>{phoneNumber ? <span>{phoneNumber}</span> : '-'}</span>
        },
      },
      {
        Header: 'Email',
        accessor: 'businessEmailAddress',
        Cell: ({ value }: any) => (value ? value : '-'),
      },
      {
        Header: 'Primary Address',
        accessor: 'billingAddresses',
        Cell: ({ value }: any) => {
          if (value && value.length > 0) {
            const primaryAddress = value?.find((address: any) => address?.isPrimary) || value[0]

            return (
              <div>
                <p>
                  {primaryAddress?.addressLine1}, {primaryAddress?.city}
                </p>
              </div>
            )
          } else {
            return <div>-</div>
          }
        },
        disableSortBy: true,
      },
      {
        Header: 'Actions',
        accessor: 'id',
        width: 10,
        Cell: ({ row }: any) => {
          return (
            <div className="flex w-full items-center justify-between ">
              <button
                className={`mr-2 ${vendorAccess ? '' : 'opacity-40'}`}
                onClick={(e) => {
                  e.stopPropagation()
                 vendorEditAccess && navigate(`${row?.original?._id}/edit/basic-details`)
                }}
              >
                <Pen />
              </button>
              <button
                className={`mr-2 ${vendorAccess ? '' : 'opacity-40'}`}
                onClick={(e) => {
                  e.stopPropagation()
                 vendorEditAccess && setDeletePopUp({ isPopUpOpen: true, VendorData: row?.original })
                }}
              >
                <Bin />
              </button>
              <span
                className="cursor-pointer"
                onClick={() => vendorAccess && navigate(`/contacts/vendor-management/${row?.original?._id}/view-basic-details`)}
              >
                <ClickableRowArrow />
              </span>
            </div>
          )
        },
        disableSortBy: true,
      },
    ],
    [],
  )

  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black ">Vendor Management</h1>
        <div className="flex justify-between">
          <p className="w-[600px] p-sm-regular-1">
            View the details of your added Vendor and easily add new ones as needed.
          </p>
        </div>

        <WhiteCard className="mt-4">
          <div className="mb-2 flex justify-between">
            <div className="flex w-full gap-3">
              <Button onClick={handleFilterToggle} startIcon={<Filter color="#3D4140" />}>
                {t('filters')}
                {activeFiltersCount === 0 ? '' : `(${activeFiltersCount})`}
              </Button>
              <SearchBox
                value={inputValue}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search Vendor by Name, Type, Contact Number or Email "
                onClearSearchBox={() => {
                  setFilters((prevFilters: any) => ({
                    ...prevFilters,
                    searchText: '',
                  }))
                  handleSearch('')
                }}
              />
            </div>
            {vendorAccess && (
              <div className="relative  flex gap-3">
                {/* <ExportButton onOptionSelect={() => {}} disabled>
                  {t('export_data')}
                </ExportButton>
                <ImportButton disabled onOptionSelect={() => {}}>
                  {t('import_data')}
                </ImportButton> */}
                <Button
                  startIcon={<PlusIcon />}
                  color="primary"
                  onClick={() => {
                    navigate('add/basic-details')
                  }}
                >
                  Add Vendor
                </Button>
              </div>
            )}
          </div>
          <PaginatedTable
            onPageNumberChange={handlePageChange}
            key={key}
            columns={columns}
            sorting={{ sortBy: filters.sortBy, sortDir: filters.sortDir }}
            rows={vendorsData?.data || []}
            currentPageNumber={filters.page_no}
            pageSize={filters.page_size}
            onPageSizeChange={handlePageNumberChange}
            // onSingleClick={handleWorkOrdersSelection}
            loading={isVendorsLoading || isVendorsFetching}
            pagination={vendorsData?.paging}
            emptyMessage={`${filters.searchText ? `No Results found for the search "${filters.searchText}" ` : 0 > 0 ? 'No Vendors found . Please adjust your filters.' : 'No Vendors are added. Please add Vendors. '}`}
            onColumnSort={handleSort}
          />
          <Panel
            size="medium"
            isOpen={showFilter}
            title="Filters"
            onClose={() => {
              setShowFilter(false)
            }}
          >
            <FilterVendor
              closePanel={() => {
                setShowFilter(false)
              }}
              filters={filters}
              setFilters={setFilters}
            />
          </Panel>
          <Popup
            isOpen={deletePopUp.isPopUpOpen}
            title="Delete Vendor Details"
            onClose={() => {
              setDeletePopUp({ isPopUpOpen: false, VendorData: null })
            }}
          >
            <DeleteVendor
              closePopUp={() => setDeletePopUp({ isPopUpOpen: false, VendorData: null })}
              VendorData={deletePopUp?.VendorData}
              plant_id={plant?.id}
            />
          </Popup>
        </WhiteCard>
      </>
    </MotionDiv>
  )
}

export default VendorManagement
