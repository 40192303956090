import BlueTick from 'assets/icons/user-management/BlueTick'
import Field from 'libs/field'

const Column = ({
  col,
  options,
  mainAccessor,
  remainingOptions,
  uniqueIdInOptions,
  rows,
  rowIndex,
  row,
  formik,
}: any) => {
  let defaultValue = null
  if (col.main && options) {
    const label = row?.[col.accessor]?.[uniqueIdInOptions]
    defaultValue = options.find((op: any) => op.id === label)
  }

  const defaultProps: any = {}
  if (col.fieldType === 'select') {
    defaultProps['defaultValue'] = col?.options?.find((op: any) => op.id === row?.[col.accessor])
  }

  const handleRadioClick = () => {
    const isCurrentlySelected = row?.[col.accessor]

    const updatedRows = rows.map((r: any, index: number) => {
      if (index === rowIndex) {
        const updatedRow = { ...r }

        if (isCurrentlySelected) {
          // Deselect the current radio button if it's already selected
          updatedRow[col.accessor] = false
        } else {
          // Reset other radio fields in the row and select the current one
          Object.keys(updatedRow).forEach((key) => {
            if (typeof row[key] === 'boolean') {
              updatedRow[key] = false
            }
          })
          updatedRow[col.accessor] = true
        }

        return updatedRow
      }
      return r
    })

    formik.setFieldValue('formikRows', updatedRows)
  }

  return (
    <>
      {col.main ? (
        <>
          {remainingOptions && (
            <Field
              alignText={col?.align}
              placeholder={col?.placeholder}
              type={'select'}
              name={`formikRows[${rowIndex}].${col.accessor}`}
              formik={formik}
              options={remainingOptions}
              defaultValue={defaultValue}
              hideborder
            />
          )}
        </>
      ) : (
        <>
          {col.editable ? (
            col.fieldType === 'radio' ? (
              <div className="flex h-[50px] items-center justify-center" onClick={handleRadioClick}>
                {row?.[col.accessor] ? (
                  <BlueTick />
                ) : (
                  <div
                    className={`box-border flex h-4 w-4  items-center justify-center rounded-full border border-primary`}
                  />
                )}
              </div>
            ) : (
              <Field
                alignText={col?.align}
                placeholder={col?.placeholder ?? 'Enter'}
                type={col?.fieldType}
                options={col?.options}
                name={`formikRows[${rowIndex}].${col.accessor}`}
                formik={formik}
                {...defaultProps}
                hideborder
              />
            )
          ) : (
            <div className="flex h-[50px] items-center justify-center">
              {col.render
                ? col.render(row?.[mainAccessor]?.[col.accessor])
                : row?.[mainAccessor]?.[col.accessor] ?? '-'}
            </div>
          )}
        </>
      )}
    </>
  )
}

export default Column
