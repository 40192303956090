import React from 'react'
import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import { useGetUsersQuery, useUserInfoQuery } from 'pages/settings/user-management/users/user-endpoints/user.endpoint'

interface Props {
  closePanel: () => void
  setFilters: (filters: any) => void
  filters: Filters
}

interface Filters {
  assignee: string[]
  deadline: number[]
  priority: string[]
  status: string[]
}
interface FormValues {
  assignee: string[]
  status: string[]
  priority: string[]
  deadline: number[]
}


const FilterMaintenanceOrder: React.FC<Props> = ({ closePanel, setFilters, filters }: Props) => {
  const { data: userInfo } = useUserInfoQuery({})
  const { data: users } = useGetUsersQuery({
    org_id: userInfo?.organization?._id,
    filters: filters,
  })
  const initialValues: FormValues = {
    assignee: filters.assignee ?? [],
    status: filters.status ?? [],
    priority: filters.priority ?? [],
    deadline: filters?.deadline?.length == 0 ? [] : filters?.deadline,
  }

  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      searchText: '',
      priority: [],
      status: [],
      deadline: [],
      assignee: [],
      sortBy: 'created_on',
      sortDir: 'DESC',
    })

    closePanel()
  }

  const filterFormik = useFormik<FormValues>({
    initialValues: initialValues,
    onSubmit: (values) => {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        deadline: values.deadline == null ? [] : values.deadline,
        assignee: values.assignee,
        status: values.status,
        priority: values.priority,
      }))
    },
  })

  const verificaton = [
    {
      name: 'In Progress',
      value: 'IN_PROGRESS',
      _id: '101',
    },
    {
      name: 'Assigned',
      value: 'ASSIGNED',
      _id: '102',
    },
    {
      name: 'Close',
      value: 'CLOSE',
      _id: '104',
    },
    {
      name: 'Cancelled',
      value: 'CANCELLED',
      _id: '105',
    },
    {
      name: 'Delayed',
      value: 'DELAYED',
      _id: '105',
    },
  ]
  const priority = [
    {
      label: 'High',
      value: 'HIGH',
      _id: '101',
    },
    {
      label: 'Medium',
      value: 'MEDIUM',
      _id: '102',
    },
    {
      label: 'Low',
      value: 'LOW',
      _id: '104',
    },
  ]

  const verificatonOptions = verificaton.map((item) => ({
    label: item.name,
    value: item.value,
    sublabel: '',
    disabled: '',
  }))

  const assignee = users?.data?.map((item: any) => ({
    label: item?.username,
    value: item?._id,
    sublabel: '',
    disabled: '',
  }))


  return (
    <>
      <div className="flex flex-col gap-3">
        <Field
          type="multiselect"
          label="Assignee"
          placeholder="Select assignee"
          multiSelectPlaceholderTexts={['Category', 'category']}
          options={assignee}
          formik={filterFormik}
          name="assignee"
        />
        {/* Status */}
        <Field
          type="multiselect"
          label="Status"
          placeholder="Select status"
          options={verificatonOptions}
          formik={filterFormik}
          name="status"
        />
        {/* Created Date Range */}
        <Field
          type="multiselect"
          fullWidth
          options={priority}
          placeholder="Select priority"
          label="Priority"
          formik={filterFormik}
          name="priority"
        />
        {/* Update Date Range */}
        <Field type="dateRange" fullWidth label="Deadline" formik={filterFormik} name="deadline" />
      </div>
      <div className="flex justify-between pt-5 ">
        <div>
          <Button
            onClick={() => {
              handleReset()
            }}
          >
            Reset Filters
          </Button>
        </div>
        <div className="flex gap-2">
          <Button onClick={closePanel}>Cancel</Button>

          <Button
            color="primary"
            type="submit"
            onClick={() => {
              filterFormik.handleSubmit()
              closePanel()
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  )
}

export default FilterMaintenanceOrder
