import { getToken } from 'utils/CheckAuth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'
import { buildUrl } from 'utils/BuildUrl'
import {
  GetSalesOrderRequest,
  GetSalesOrderResponse,
  PostSalesOrderRequest,
  PostSalesOrderResponse,
} from './salesOrdersTypes'

export const sales = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    getAllSalesOrders: builder.query<GetSalesOrderResponse, GetSalesOrderRequest>({
      query: ({ plantId, filters }) => {
        const URL = buildUrl(`/plant/${plantId}/so`, filters)
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Sales Orders ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['salesOrderUpdate'],
    }),
    getSalesOrderByID: builder.query({
      query: ({ plant_id, soID }) => {
        return {
          url: `/plant/${plant_id}/so/${soID}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Sales Orders ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['salesOrderUpdate'],
    }),
    postSalesOrder: builder.mutation<PostSalesOrderResponse, PostSalesOrderRequest>({
      query: ({ data, plant_id }) => {
        return {
          url: `/plant/${plant_id}/sales-order`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['salesOrderUpdate'],
    }),
    putSalesOrder: builder.mutation({
      query: ({ plant_id, salesOrderId, data }) => {
        return {
          url: `/plant/${plant_id}/so/${salesOrderId}`,
          method: 'PUT',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['salesOrderUpdate'],
    }),
    deleteSalesOrder: builder.mutation({
      query: ({ plant_id, soID }) => ({
        url: `/plant/${plant_id}/so/${soID}`,
        method: 'DELETE',
        headers: getToken(),
      }),
      invalidatesTags: ['salesOrderUpdate'],
    }),
    getSalesOrdersStats: builder.query<any, { plantId: string }>({
      query: ({ plantId }) => ({
        url: `/plant/${plantId}/so/stats`,
        method: 'GET',
        headers: getToken(),
      }),
      providesTags: ['salesOrderUpdate'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllSalesOrdersQuery,
  useGetSalesOrderByIDQuery,
  usePostSalesOrderMutation,
  usePutSalesOrderMutation,
  useDeleteSalesOrderMutation,
  useGetSalesOrdersStatsQuery,
} = sales
