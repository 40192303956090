import { useState } from 'react'
import MotionDiv from 'libs/motionDiv'
import useTabTitle from 'libs/hooks/useTabTitle'
import { useNavigate } from 'react-router-dom'
import { useGetPurchaseOrdersQuery } from './purchase-orders-endpoints/purchaseOrders.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { formatUnixDate } from 'utils/unixConverter'
import Pen from 'assets/icons/camera-page/Pen'
import Bin from 'assets/icons/camera-page/Bin'
import FilterPurchaseOrder from './panel/FilterPurchaseOrder'
import DeletePurchaseOrder from './delete-purchase-order/DeletePurchaseOrder'
import Popup from 'libs/popup/Popup'
import Chip from 'libs/chip/Chip'
import ClickableRowArrow from 'assets/icons/camera-page/ClickableRowArrow'
import FilterableSearchTable from 'libs/table/filterable-search-table/FilterableSearchTable'
import { hasAccess } from 'utils/hasAccess'

const additionalFilters = {
  createdBetween: [],
  updatedBetween: [],
  status: [],
  category: [],
  sortBy: 'created_on',
  sortDir: 'DESC',
}

const PurchaseOrder = () => {
  const plantId = useSelector(selectSelectedItem)
  const [deletePopUp, setDeletePopUp] = useState({ isPopUpOpen: false, purchaseOrderID: '' })
  const navigate = useNavigate()
  const purchaseOrderAccess = hasAccess('ord', { po: 'v' })
  const purchaseOrderEditAccess = hasAccess('ord', { po: 'e' })
  useTabTitle('Purchase Order')

  const column = [
    {
      Header: 'Purchase Order ID',
      accessor: 'purchaseOrderID',
      width: 130,
      Cell: ({ row }: any) => {
        const data = row?.original
        return (
          <div
            className={`${purchaseOrderAccess ? 'hyperlink' : ''}`}
            onClick={() => {
              purchaseOrderAccess && navigate(`/orders/purchase-order/view/${data?._id}`)
            }}
          >
            {data?.purchaseOrderID}
          </div>
        )
      },
    },
    {
      Header: <div className="flex w-full items-center justify-end">Supplier Name</div>,
      accessor: 'supplierName',
      width: 150,
      Cell: ({ row }: any) => {
        return <div>{row.original.vendor?.name}</div>
      },
    },
    {
      Header: <div className="flex w-full items-center justify-end">Raw Materials</div>,
      accessor: 'products',
      width: 150,
      Cell: ({ row }: any) => {
        const data = row?.original
        return (
          <div
            className={`${purchaseOrderAccess ? 'hyperlink' : ''}`}
            onClick={() => {
              purchaseOrderAccess && navigate(`/orders/purchase-order/view/${data?._id}`)
            }}
          >
            {data?.products?.length} Raw Materials
          </div>
        )
      },
    },
    {
      Header: <div className="flex w-full items-center justify-end">Arrival Date</div>,
      accessor: 'arrivalDate',
      width: 130,
      Cell: ({ value }: any) => formatUnixDate(value),
    },
    {
      Header: <div className="flex w-full items-center justify-end">Status</div>,
      accessor: 'status',
      width: 50,
      Cell: ({ value }: any) => <Chip title={value} />,
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      width: 50,
      Cell: ({ row }: any) => (
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-row">
            <button
              className={`mr-2 ${purchaseOrderEditAccess ? '': 'opacity-40'}`}
              onClick={(e) => {
                e.stopPropagation()
                purchaseOrderEditAccess && navigate(`edit/${row.original._id}`)
              }}
            >
              <Pen />
            </button>
            <button
              className={`mr-2 ${purchaseOrderEditAccess ? '': 'opacity-40'}`}
              onClick={(event) => {
                event.stopPropagation()
                purchaseOrderEditAccess && setDeletePopUp({ isPopUpOpen: true, purchaseOrderID: row?.original })
              }}
            >
              <Bin />
            </button>
          </div>
          <span
            className="cursor-pointer"
            onClick={() => purchaseOrderAccess && navigate(`/orders/purchase-order/view/${row?.original?._id}`)}
          >
            <ClickableRowArrow />
          </span>
        </div>
      ),
      disableSortBy: true,
    },
  ]

  const handleAddPurchaseOrder = () => {
    purchaseOrderAccess && navigate('add')
  }

  

  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black ">Purchase Order</h1>
        <p className="p-sm-regular-1">
          View the details of your added Purchase Orders and easily add new ones as needed.
        </p>
        <FilterableSearchTable
          useQuery={useGetPurchaseOrdersQuery}
          columns={column}
          addButton={{ label: 'Add Purchase Order', onClick: handleAddPurchaseOrder }}
          placeholders={{
            emptyMessage: 'No Purchase Order are added. Please add Purchase Order',
            filterEmptyMessage: 'No matching results found',
            search: 'Search Purchase Orders by ID, Supplier Name or Status',
          }}
          filterPanelComponent={FilterPurchaseOrder}
          additionalFilters={additionalFilters}
          accessRights={purchaseOrderEditAccess}
        />
        <Popup
          isOpen={deletePopUp.isPopUpOpen}
          title="Delete Purchase Order"
          onClose={() => {
            setDeletePopUp({ isPopUpOpen: false, purchaseOrderID: '' })
          }}
        >
          <DeletePurchaseOrder
            closePopUp={() => setDeletePopUp({ isPopUpOpen: false, purchaseOrderID: '' })}
            poID={deletePopUp.purchaseOrderID}
            plant_id={plantId?.id}
          />
        </Popup>
      </>
    </MotionDiv>
  )
}

export default PurchaseOrder
