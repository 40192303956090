import { useState, useEffect } from 'react'
import Inventory from 'assets/icons/dashboard/Inventory'
import Production from 'assets/icons/dashboard/Production'
import Review from 'assets/icons/dashboard/Review'
import Planning from 'assets/icons/dashboard/Planning'
import Dashboard from 'assets/icons/dashboard/Dashboard'
import Order from 'assets/icons/dashboard/Order'
import Employee from 'assets/icons/dashboard/Employee'
import Contact from 'assets/icons/dashboard/Contact'
import TimeSheet from 'assets/icons/timesheet/TimeSheet'
import { hasAccess } from 'utils/hasAccess'
import Cookies from 'js-cookie'
import Maintenance from 'assets/icons/dashboard/Maintenance'

export const useMenuData = () => {
  const [menuData, setMenuData] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const role = Cookies.get('role')

    const purchaseOrderAccess = hasAccess('ord', { po: 'v' })
    const salesOrderAccess = hasAccess('ord', { so: 'v' })
    const inventoryAccess = hasAccess('inv', { inv: 'v' })
    const productionAccess = hasAccess('pro', { pro: 'v' })
    const employeeRolesAccess = hasAccess('emp', { er: 'v' })
    const employeeDirectoryAccess = hasAccess('emp', { ed: 'v' })
    const customerAccess = hasAccess('con', { cus: 'v' })
    const vendorAccess = hasAccess('con', { ven: 'v' })
    const inventoryDashboardAccess = hasAccess('das', { id: 'v' })
    const workorderDashboardAccess = hasAccess('das', { wd: 'v' })
    const manufactureOrderDashboardAccess = hasAccess('das', { md: 'v' })
    const reviewAccess = hasAccess('rev', { rev: 'v' })
    const productionPlansAccess = hasAccess('pla', { pp: 'v' })
    const manufactureOrderAccess = hasAccess('pla', { mo: 'v' })
    const workorderAccess = hasAccess('pla', { wo: 'v' })
    const timesheetViewAccess = hasAccess('tim', { ts: 'v' })

    const menu = [
      (inventoryDashboardAccess || workorderDashboardAccess || manufactureOrderDashboardAccess) && {
        title: 'dashboard',
        icon: <Dashboard color={undefined} />,
        path: '/dashboard',
        value: true,
        role: [role],
      },
      productionAccess && {
        title: 'production',
        icon: <Production color={undefined} />,
        path: '/productionV2',
        value: true,
        role: [role],
      },
      reviewAccess && {
        title: 'review',
        path: '/review',
        icon: <Review color={undefined} />,
        value: true,
        role: [role],
      },
      (productionPlansAccess || manufactureOrderAccess || workorderAccess) && {
        title: 'planning',
        path: '/planning',
        icon: <Planning color={undefined} />,
        value: true,
        role: [role],
      },
      inventoryAccess && {
        title: 'inventory',
        icon: <Inventory color={undefined} />,
        path: '/inventory',
        value: true,
        role: [role],
      },
      (purchaseOrderAccess || salesOrderAccess) && {
        title: 'Orders',
        icon: <Order color={undefined} />,
        path: '/orders',
        value: true,
        role: [role],
      },
      (employeeDirectoryAccess || employeeRolesAccess) && {
        title: 'Employee',
        icon: <Employee />,
        path: '/employee',
        value: true,
        role: [role],
      },
      (vendorAccess || customerAccess) && {
        title: 'Contacts',
        icon: <Contact />,
        path: '/contacts',
        value: true,
        role: [role],
      },

      timesheetViewAccess && role !='admin' && {
        title: 'TimeSheet',
        icon: <TimeSheet />,
        path: '/timesheet',
        value: true,
        role: [role],
      },
      {
        title: 'Maintenance',
        icon: <Maintenance />,
        path: '/maintenance',
        value: true,
        role: [role],
      },
    ].filter(Boolean)

    setMenuData(menu)
    setLoading(false)
  }, [])

  return { menuData, loading }
}
