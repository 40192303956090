import { getToken } from 'utils/CheckAuth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'

import { buildUrl } from 'utils/BuildUrl'

import { GetMaintenanceRequest, getMaintenanceRequestByIdParams, GetMaintenanceRequestParams, MaintenanceRequestResponse, PostMaintenanceRequestParams, PostMaintenanceRequestResponse } from './maintenance-requestTypes'

export const MaintenanceRequest = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    getMaintenanceRequests: builder.query<MaintenanceRequestResponse, GetMaintenanceRequestParams>({
      query: (params) => {
        let URL = ''
        params.filters
          ? (URL = buildUrl(`/plant/${params.plantId}/maintenance-requests`, params.filters))
          : (URL = `/plant/${params.plantId}/maintenance-requests`)
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the maintenance requests')
            }
            return response.json()
          },
        }
      },
      providesTags: ['getMaintenanceRequests'],
    }),

    postMaintenanceRequest: builder.mutation<PostMaintenanceRequestResponse, PostMaintenanceRequestParams>({
      query: ({ data, plant_id }) => {
        return {
          url: `plant/${plant_id}/maintenance-request`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['getMaintenanceRequests'],
    }),

    deleteMaintenanceRequest: builder.mutation<any, { plantId: string,data: any}>({
      query: ({ plantId, data }) => ({
        url: `/plant/${plantId}/maintenance-request`,
        method: 'DELETE',
        headers: getToken(),
        body:data
      }),
      invalidatesTags: ['getMaintenanceRequests'],
    }),
    putMaintenanceRequest: builder.mutation<any, any>({
      query: ({ data, plantId, mrID }) => {
        return {
          url: `/plant/${plantId}/maintenance-request/${mrID}`,
          method: 'PUT',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['getMaintenanceRequests'],
    }),
    getMaintenanceRequestByID: builder.query<GetMaintenanceRequest, getMaintenanceRequestByIdParams>({
      query: ({ plant_id, mrID }) => {
        return {
          url: `/plant/${plant_id}/maintenance-request/${mrID}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Maintenance Request ')
            }
            return response.json()
          },
        }
      },
    }),

    getCountMaintenanceRequest: builder.query({
      query: ({ plant_id }) => {
        return {
          url: `plant/${plant_id}/maintenance-request-count`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the count of  Maintenance Request')
            }
            return response.json()
          },
        }
      },
      providesTags: ['GetMaintenanceRequestsCount'],
    }),
  }),
  overrideExisting: false,
})

export const {
useGetMaintenanceRequestsQuery,
usePostMaintenanceRequestMutation,
usePutMaintenanceRequestMutation,
useGetMaintenanceRequestByIDQuery,
useDeleteMaintenanceRequestMutation,
useGetCountMaintenanceRequestQuery,
} = MaintenanceRequest
