import Bin from 'assets/icons/camera-page/Bin'
import ClickableRowArrow from 'assets/icons/camera-page/ClickableRowArrow'
import Pen from 'assets/icons/camera-page/Pen'
import Chip from 'libs/chip/Chip'
import useTabTitle from 'libs/hooks/useTabTitle'
import MotionDiv from 'libs/motionDiv'
import Popup from 'libs/popup/Popup'
import FilterableSearchTable from 'libs/table/filterable-search-table/FilterableSearchTable'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectSelectedItem } from 'store/redux/navSlice'
import { formatUnixDate } from 'utils/unixConverter'
import FilterMaintenanceOrder from './panel/FilterMaintenanceOrder'
import DeleteMaintenanceOrderPopup from './popups/DeleteMaintenanceOrderPopup'
import { useGetMaintenanceOrdersQuery } from './maintenance-order-endpoints/order.endpoints'

const MaintenanceOrder = () => {
  const plantId = useSelector(selectSelectedItem)
  const navigate = useNavigate()
  useTabTitle('Maintenance Orders')
  const [deletePopUp, setDeletePopUp] = useState({ isPopUpOpen: false, maintenanceOrderID: '' })

  const column = [
    {
      Header: 'Order ID',
      accessor: 'orderID',
      width: 80,
      Cell: ({ row }: any) => {
        const data = row?.original
        return (
          <div
            className="hyperlink"
            onClick={() => {
              navigate(`/maintenance/maintenance-order/${data?._id}/view`)
            }}
          >
            {data?.orderID}
          </div>
        )
      },
    },
    {
      Header: <div className="flex w-full items-center justify-end">Task Name</div>,
      accessor: 'task_name',
      width: 150,
      Cell: ({ row }: any) => {
        return <div>{row?.original?.task_name}</div>
      },
    },
    {
      Header: <div className="flex w-full items-center justify-end">Assignee</div>,
      accessor: 'assignees',
      width: 150,
      Cell: ({ row }: any) => {
        const data = row?.original
        return (
          <div
          >
            {data?.assignees.length} Assignees
          </div>
        )
      },
      disableSortBy: true,
    },
    {
      Header: <div className="flex w-full items-center justify-end">Deadline</div>,
      accessor: 'deadline',
      width: 150,
      Cell: ({ value }: any) => formatUnixDate(value),
    },
    {
      Header: <div className="flex w-full items-center justify-end">Priority</div>,
      accessor: 'priority_level',
      width: 80,
      Cell: ({ value }: any) => <Chip title={value} size='sm' />,
      disableSortBy: true,
    },
    {
      Header: <div className="flex w-full items-center justify-end">Status</div>,
      accessor: 'status',
      width: 80,
      Cell: ({ value }: any) => <Chip title={value} />,
      disableSortBy: true,
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      width: 10,
      Cell: ({ row }: any) => (
        <div className="flex w-full items-center justify-between ">
          <div className="flex flex-row">
            <button
              className={`mr-2`}
              onClick={(e) => {
                e.stopPropagation()
                navigate(`/maintenance/maintenance-order/edit/${row.original._id}`)
              }}
            >
              <Pen />
            </button>
            <button
              className={`mr-2`}
              onClick={(event) => {
                event.stopPropagation()
                setDeletePopUp({ isPopUpOpen: true, maintenanceOrderID: row?.original })
              }}
            >
              <Bin />
            </button>
          </div>

          <span className="cursor-pointer" onClick={() => navigate(`/maintenance/maintenance-order/${row?.original?._id}/view`)}>
            <ClickableRowArrow />
          </span>
        </div>
      ),
      disableSortBy: true,
    },
  ]
  const handleCreateMaintenanceOrder = () => {
    navigate('/maintenance/maintenance-order/add')
  }
  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black ">Maintenance Order</h1>
        <p className="p-sm-regular-1">
          Assign specific tasks to employees to address maintenance needs, based on the reviewed requests.
        </p>
        <FilterableSearchTable
          columns={column}
          useQuery={useGetMaintenanceOrdersQuery}
          addButton={{ label: 'Create Maintenance Order', onClick: handleCreateMaintenanceOrder }}
          placeholders={{
            emptyMessage: 'No Maintenance orders are added. Please add Maintenance order',
            filterEmptyMessage: 'No matching results found',
            search: 'Search Order by ID, task name',
          }}
          filterPanelComponent={FilterMaintenanceOrder}
        />
        <Popup
          isOpen={deletePopUp.isPopUpOpen}
          title="Delete Maintenance Order"
          onClose={() => {
            setDeletePopUp({ isPopUpOpen: false, maintenanceOrderID: '' })
          }}
        >
          <DeleteMaintenanceOrderPopup
            closePopUp={() => setDeletePopUp({ isPopUpOpen: false, maintenanceOrderID: '' })}
            maintenanceOrder={deletePopUp.maintenanceOrderID}
            
            plant_id={plantId?.id}
          />
        </Popup>
      </>
    </MotionDiv>
  )
}

export default MaintenanceOrder
