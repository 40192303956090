import Button from "libs/button/Button"
import {  useCloseMaintenanceOrderStatusMutation } from "../maintenance-order-endpoints/order.endpoints"
import { toast } from "libs/toast"
import { useNavigate } from 'react-router-dom'
const CloseMaintenanceOrderPopup = ({ closePopUp, maintenanceOrder, plant_id }: any) => {
  const navigate=useNavigate()
  const handleDelete = () => {



    CloseMaintenanceOrder({ plant_id: plant_id, moID:maintenanceOrder._id})
      .unwrap()
      .then(() => {
        closePopUp()
        navigate('/maintenance/maintenance-order')
      
        toast.success(`The Order ${maintenanceOrder.orderID} is closed successfully.`)
      })
      .catch((err) => {
        toast.error(err?.data?.detail ?? 'Something went wrong. Please try again')
      })
  }

    
    const [CloseMaintenanceOrder, { isLoading: deleteLoading }] = useCloseMaintenanceOrderStatusMutation()
    return (
      <div>
        <p className="p-xs-regular">
        You are about to mark the order{' '}
          <span className="break-words pr-1 p-xs-bold"> "{maintenanceOrder?.orderID}”.</span>
          as closed. Operators will not be able to add further entries for this plan. Are you sure you want to proceed?
        </p>
  
        <div className=" mt-4 flex justify-end gap-3">
          <Button onClick={closePopUp}>Cancel</Button>
          <Button color="primary" onClick={handleDelete} loading={deleteLoading}>
          Close
          </Button>
        </div>
      </div>
    )
  }

  export default CloseMaintenanceOrderPopup;
  