import WhiteCard from 'libs/card/WhiteCard'
import Button from 'libs/button/Button'

import MotionDiv from 'libs/motionDiv'

import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { hasAccess } from 'utils/hasAccess'

const ViewJobDetails = ({ employeeData, setPage }: any) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const Employee = employeeData?.data?.[0]
  const employeeDirectoryAccess = hasAccess('emp', { ed: 'e' })
  const employeeInfo = [
    { label: 'Department', key: 'department' },
    { label: 'Designation', key: 'designation' },
  ]

  useEffect(() => {
    setPage(3)
  }, [])

  return (
    <MotionDiv>
      <WhiteCard className=" flex min-h-[70vh] flex-col gap-6">
        <div className="flex w-full justify-between">
          <h5 className="h5">Job Details</h5>
          {employeeDirectoryAccess && (
            <div>
              <Button
                color="primary"
                onClick={() => {
                  navigate(`/employee/employee-management/${id}/edit/job-details`)
                }}
              >
                Edit Job Details
              </Button>
            </div>
          )}
        </div>
        <div className="flex  max-w-[800px] justify-between">
          <div className="grid grid-cols-2 gap-4 text-xs">
            {employeeInfo.map((field, fieldIndex) => (
              <React.Fragment key={fieldIndex}>
                <span className="text-gray-500">{field.label}</span>
                <span>
                  {typeof Employee?.[field.key] === 'object'
                    ? Employee[field.key]?.name || '-'
                    : Employee?.[field.key] || '-'}
                </span>
              </React.Fragment>
            ))}
          </div>
        </div>
      </WhiteCard>
    </MotionDiv>
  )
}

export default ViewJobDetails
