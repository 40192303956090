import { useParams } from 'react-router'
import { useGetProductionPlanQuery } from '../production-plans-endpoints/productionPlan.endpoints'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import MotionDiv from 'libs/motionDiv'
import WhiteCard from 'libs/card/WhiteCard'
import { useNavigate } from 'react-router-dom'
import Button from 'libs/button/Button'
import PaginatedTable from 'libs/table/PaginatedTable'
import { formatUnixDate, formatUnixDateVerbose, getDaysRemaining } from 'utils/unixConverter'
import Chip from 'libs/chip/Chip'
import FullPageLoader from 'libs/loader/FullPageLoader'
import Popup from 'libs/popup/Popup'
import { useMemo, useState } from 'react'
import DeletePlan from '../popups/DeletePlan'
import StartPlan from '../popups/StartPlan'
import { hasAccess } from 'utils/hasAccess'

const ViewPlan = () => {
  const { id } = useParams()
  const [deletePopUp, setDeletePopUp] = useState({ isPopUpOpen: false, plan: '' })
  const [startPopUp, setStartPopUp] = useState({ isPopUpOpen: false, plan: '' })
  const productionPlansAccess = hasAccess('pla', { pp: 'e' })
  const manufactureOrderAccess = hasAccess('pla', { mo: 'v' })
  const workOrderAccess = hasAccess('pla', { wo: 'v' }) 
  const salesOrderAccess = hasAccess('pla', { so: 'v' })
  const purchaseOrderAccess = hasAccess('pla', { po: 'v' })
  const plantId = useSelector(selectSelectedItem)
  const navigate = useNavigate()
  const filters = {
    planID: id,
    page_no: 1,
    page_size: 10,
    sortBy: 'created_on',
    sortDir: 'DESC',
  }
  const { data, isFetching, isLoading } = useGetProductionPlanQuery(
    {
      plantId: plantId?.id,
      filters: filters,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !plantId?.id,
    },
  )

  const columns = [
    {
      Header: 'MO ID',
      accessor: 'manufactureOrderID',
      width: 120,
    },
    {
      Header: 'Finished Good',
      accessor: 'finishedGood',
      width: 120,
      Cell: ({ row }: any) => {
        return (
          <>
            {row?.original?.finishedGood?.name ? (
              <div>
                <p className="text-sm">{row?.original?.finishedGood?.name}</p>
                <p className="text-md text-gray-600">{row?.original?.finishedGood?.code}</p>
              </div>
            ) : (
              '-'
            )}
          </>
        )
      },
    },
    {
      Header: 'Sales Order',
      accessor: 'salesOrders',
      width: 100,
      Cell: ({ value }: any) =>
        value?.length != 0 ? (
          <button className={`${salesOrderAccess ? 'hyperlink' : ''}`} type="button" onClick={()=> navigate(`/orders/sales-order`)}>
            {value.length} SOs
          </button>
        ) : (
          '-'
        ),
    },
    {
      Header: 'Purchase Order',
      accessor: 'purchaseOrders',
      width: 100,
      Cell: ({ value }: any) =>
        value?.length != 0 ? (
          <button className={`${purchaseOrderAccess ? 'hyperlink' : ''}`} type="button" onClick={()=> navigate(`/orders/purchase-order`)}>
            {value.length} POs
          </button>
        ) : (
          '-'
        ),
    },
    {
      Header: 'Work Order',
      accessor: 'workOrders',
      width: 100,
      Cell: ({ value }: any) =>
        value?.length != 0 ? (
          <button className={`${workOrderAccess ? 'hyperlink' : ''}`} type="button" onClick={()=> navigate(`/planning/work-orders`)}>
            {value.length} WOs
          </button>
        ) : (
          '-'
        ),
    },
    {
      Header: 'Start Date',
      accessor: 'startDate',
      Cell: ({ row }: any) => {
        return (
          <>
            {row?.original?.startDate ? (
              <div>
                <p className="p-xs-regular">{formatUnixDate(row?.original?.startDate)}</p>
              </div>
            ) : (
              '-'
            )}
          </>
        )
      },
    },
    {
      Header: 'End Date',
      accessor: 'endDate',
      Cell: ({ row }: any) => {
        return (
          <>
            {row?.original?.endDate ? (
              <div>
                <p className="p-xs-regular">{formatUnixDate(row?.original?.endDate)}</p>
              </div>
            ) : (
              '-'
            )}
          </>
        )
      },
    },
  ]

  const scheduledDateArray = data?.data?.[0]?.scheduledDate
  const start = Array.isArray(scheduledDateArray) ? scheduledDateArray[0] : null
  const end = Array.isArray(scheduledDateArray) ? scheduledDateArray[1] : null

  const startDate = start !== null ? formatUnixDateVerbose(start, plantId.timezone) : null
  const endDate = end !== null ? formatUnixDateVerbose(end, plantId.timezone) : null
  const planData = data?.data?.[0] || ''


const filteredManufactureOrders = useMemo(() => {
  return data?.data[0]?.manufactureOrders?.filter((mo: any) => mo?._id)
}, [data])

  return (
    <MotionDiv>
      <>
        {isLoading ? (
          <FullPageLoader />
        ) : (
          <WhiteCard>
            <>
              <div className="flex items-center justify-between">
                <div>
                  <h1 className="text-[20px] font-medium">{data?.data[0]?.planID}</h1>
                </div>
                {productionPlansAccess && (
                  <div className="flex gap-2">
                    <Button onClick={() => navigate(`/planning/production-plans/${id}/edit/plan-details`)}>Edit</Button>
                    {data?.data[0]?.status != 'COMPLETED' && data?.data[0]?.status != 'IN_PROGRESS' && (
                      <Button onClick={() => setDeletePopUp({ isPopUpOpen: true, plan: planData as string })}>
                        Delete
                      </Button>
                    )}
                    {data?.data[0]?.status != 'COMPLETED' && data?.data[0]?.status != 'NOT_SCHEDULED' && (
                      <Button
                        color="primary"
                        onClick={() => setStartPopUp({ isPopUpOpen: true, plan: planData as string })}
                      >
                        {data?.data[0]?.status == 'IN_PROGRESS' ? 'Mark as Complete' : 'Start Plan'}
                      </Button>
                    )}
                  </div>
                )}
              </div>
              <div className="flex w-full items-center gap-3">
                {data?.data[0].status != 'NOT_SCHEDULED' && (
                  <>
                    <div className="flex items-center gap-2 border-r pr-2 ">
                      <span className="text-xs">{startDate}</span>
                      <span>-{'>'}</span>
                      <span className="text-xs">{endDate}</span>
                    </div>
                    <div className="flex items-center gap-2 border-r py-[4px] pr-2">
                      <span className="text-xs ">{getDaysRemaining(start as number, end as number)} days</span>
                    </div>
                  </>
                )}
                <span className="pr-2">
                  <Chip size="sm" title={data?.data[0]?.status} />
                </span>
              </div>
              <p className="py-2 text-sm text-gray-600">{data?.data[0].description}</p>
              <div className="flex justify-between">
                <h1 className="font-medium">Manufacturing Orders</h1>
                {manufactureOrderAccess && (
                  <span
                    className="cursor-pointer text-sm text-primary underline"
                    onClick={() => navigate('/planning/mo')}
                  >
                    Manage Manufacturing Orders
                  </span>
                )}
              </div>

              <div>
                <PaginatedTable
                  columns={columns}
                  rows={data?.data[0]?.manufactureOrders?.filter((mo: any) => mo?._id)}
                  currentPageNumber={filters.page_no}
                  pageSize={filters.page_size}
                  loading={isFetching}
                  sorting={{ sortBy: filters.sortBy, sortDir: filters.sortDir }}
                  pagination={data?.paging}
                  emptyMessage={'Manufacture Order does not exist.'}
                  hidePagination
                />
              </div>
              <Popup
                isOpen={deletePopUp.isPopUpOpen}
                title="Delete Plan"
                onClose={() => {
                  setDeletePopUp({ isPopUpOpen: false, plan: '' })
                }}
              >
                <DeletePlan
                  closePopUp={() => {
                    setDeletePopUp({ isPopUpOpen: false, plan: '' })
                  }}
                  pID={deletePopUp.plan}
                  plant_id={plantId?.id}
                />
              </Popup>
              <Popup
                isOpen={startPopUp.isPopUpOpen}
                title={`${data?.data[0].status == 'IN_PROGRESS' ? 'Mark as complete' : 'Start Plan'}`}
                onClose={() => {
                  setStartPopUp({ isPopUpOpen: false, plan: '' })
                }}
              >
                <StartPlan
                  closePopUp={() => {
                    setStartPopUp({ isPopUpOpen: false, plan: '' })
                  }}
                  pID={startPopUp.plan}
                  plant_id={plantId?.id}
                  statusValue={data?.data[0].status}
                  moData={filteredManufactureOrders}
                />
              </Popup>
            </>
          </WhiteCard>
        )}
      </>
    </MotionDiv>
  )
}

export default ViewPlan
