import Chip from "libs/chip/Chip"
import PaginatedTable from "libs/table/PaginatedTable"
import { useNavigate } from "react-router-dom"

const ImpactedProductionPlans = () => {
    const navigate = useNavigate()
  const column = [
    {
      Header: 'Plan ID',
      accessor: 'OrderID',
      width: 80,
      Cell: ({ row }: any) => {
        const data = row?.original
        return (
          <div
            className="hyperlink"
            onClick={() => {
              navigate(`/orders/sales-order/view/${data?._id}`)
            }}
          >
            {data?.salesOrderID}
          </div>
        )
      },
    },
    {
      Header: <div className="flex w-full items-center justify-end">Manufacturing Order</div>,
      accessor: 'customerName',
      width: 150,
      Cell: ({ row }: any) => {
        return <div>{row.original.customer?.name}</div>
      },
    },
    {
      Header: <div className="flex w-full items-center justify-end">Finished Goods</div>,
      accessor: 'products',
      width: 150,
      Cell: ({ row }: any) => {
        const data = row?.original
        return (
          <div
            className="hyperlink"
            onClick={() => {
              navigate(`/orders/sales-order/view/${data?._id}`)
            }}
          >
            {data?.products.length} Products
          </div>
        )
      },
      disableSortBy: true,
    },
    {
      Header: <div className="flex w-full items-center justify-end">Status</div>,
      accessor: 'priority',
      width: 80,
      Cell: ({ value }: any) => <Chip title={value} />,
      disableSortBy: true,
    },
    {
      Header: <div className="flex w-full items-center justify-end">Duration</div>,
      accessor: 'status',
      width: 80,
      Cell: ({ value }: any) => <Chip title={value} />,
      disableSortBy: true,
    },
  ]
  return (
    <>
      <h1 className="mb-4 text-xl font-medium text-brand-primary-shade-3">Impacted Production Plans</h1>
      <PaginatedTable columns={column} rows={[]} />
    </>
  )
}

export default ImpactedProductionPlans
