import Card from 'libs/card/Card'
import { useNavigate } from 'react-router-dom'
import MotionDiv from 'libs/motionDiv'
import Button from 'libs/button/Button'

import icons from 'assets/index.ts'

import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useGetCountMaintenanceRequestQuery } from './Maintenance-request/maintenance-request/maintenance-request'

interface CardDetail {
  icon: React.ReactNode
  title: string
  subtitle: string
  route: string
  disabled: boolean
}

const Maintenance = () => {
  const plant = useSelector(selectSelectedItem)
  const { data: maintenanceRequestCount } = useGetCountMaintenanceRequestQuery(
    {
      plant_id: plant?.id,
    },
    {
      skip: !plant.id,
    },
  )

  const navigate = useNavigate()

  const cardDetails = [
    {
      icon: <img src={icons.MaintenanceRequest} alt="" />,
      title: 'Maintenance Requests',

      subtitle: `${maintenanceRequestCount?.count ?? 0} Maintenance Requests`,
      route: '/maintenance/maintenance-request',
    },
    {
      icon: <img src={icons.MaintenanceOrder} alt="" />,
      title: 'Maintenance Orders',

      subtitle: 'Maintenance Orders',
      route: '/maintenance/maintenance-order',
    },
  ].filter(Boolean) as CardDetail[]

  return (
    <MotionDiv>
      <div className="h-full">
        <h1 className="h4-bold-black">Maintenance </h1>

        <div className="relative h-full w-full">
          <div className="grid h-full w-full grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 ">
            {cardDetails.map((card, index) => (
              <div key={`${index}_${card.title}`}>
                <Card
                  key={`${index}_${card.title}`}
                  disabled={card.disabled}
                  title={card?.title}
                  subtitle={card.subtitle}
                  leftIcon={card.icon}
                  rightCenterIcon={<Button color="primary"> View </Button>}
                  onClick={() => navigate(card.route)}
                  titleStyle="font-medium text-gray-800"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </MotionDiv>
  )
}

export default Maintenance
