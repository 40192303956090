import Bin from 'assets/icons/camera-page/Bin'
import Pen from 'assets/icons/camera-page/Pen'
import MotionDiv from 'libs/motionDiv'
import FilterableSearchTable from 'libs/table/filterable-search-table/FilterableSearchTable'
import { useNavigate } from 'react-router-dom'
import { FormatTimeDifference } from 'utils/FormatTimeDifference'
import FilterUserRoles from './panel/FilterUserRoles'
import { useGetRolesQuery } from './roles-endpoints/roles.endpoint'
import { useState } from 'react'
import Popup from 'libs/popup/Popup'
import DeleteRolesPopup from './delete-roles/DeleteRolesPopup'
import useTabTitle from 'libs/hooks/useTabTitle'

const UserRoles = () => {
  const navigate = useNavigate()
  useTabTitle('User-Roles');
  const [isPopupOpen, setIsPopupOpen] = useState({ isPopupOpen: false, roleData: null })
  const column = [
    {
      Header: <div className="flex w-full items-center justify-end">User Roles</div>,
      accessor: 'name',
      width: 150,
      Cell: ({ row }: any) => {
        return row.original?.name !== 'admin' ? (
          <div className="hyperlink" onClick={() => navigate(`view/${row?.original?._id}`)}>
            {row.original?.name}
          </div>
        ) : (
          <div>{row.original?.name}</div>
        )
      },
    },
    {
      Header: 'Applicable Plants',
      accessor: 'plants',
      width: 150,
      Cell: ({ value }: any) => {
        let displayNames = value?.slice(0, 2).map((item: any) => item.name) // Display only the first two names
        const remainingCount = value?.length - displayNames?.length

        if (remainingCount > 0) {
          displayNames = [...displayNames, `${remainingCount}+`]
        }

        return displayNames.length > 0 ? (
          <div className="flex flex-wrap">
            {Array.isArray(displayNames) ? (
              displayNames?.map((name, index) => (
                <span key={index} className={`m-1 rounded-[28px] bg-[#E7F5FF] px-2 py-0.5 text-[#0B5ED7]`}>
                  {name}
                </span>
              ))
            ) : (
              <span className="text-[#434C52]">{displayNames}</span>
            )}
          </div>
        ) : (
          <div>-</div>
        )
      },
      disableSortBy: true,
    },
    {
      Header: 'Last updated',
      accessor: 'updated_on',
      Cell: ({ value }: any) => {
        const formattedDate = FormatTimeDifference(value)
        return formattedDate
      },
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      width: 10,
      Cell: ({ row }: any) => {
        return (
          <div className="flex w-full items-center justify-between ">
            <div className="flex flex-row">
              <button
                className={`${row.original?.name != 'admin' ? '' : 'opacity-40'} mr-2`}
                onClick={(e) => {
                  if (row.original?.name != 'admin') {
                    e.stopPropagation()
                    navigate(`edit/${row.original._id}`)
                  } else undefined
                }}
              >
                <Pen />
              </button>
              <button
                className={`${row.original?.name !== 'admin' ? '' : 'opacity-40'} mr-2`}
                onClick={(event) => {
                  if (row.original?.name != 'admin') {
                    event.stopPropagation()
                    setIsPopupOpen({ isPopupOpen: true, roleData: row?.original })
                  }
                }}
              >
                <Bin />
              </button>
            </div>
          </div>
        )
      },
      disableSortBy: true,
    },
  ]
  const handleAddUserRoles = () => {
    navigate('/settings/user-management/roles/add')
  }
  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black">User Roles</h1>
        <p className="p-sm-regular-1">Manage and overview all user roles</p>
        <FilterableSearchTable
          useOrgQuery={useGetRolesQuery}
          columns={column}
          addButton={{ label: 'Add User Role', onClick: handleAddUserRoles }}
          placeholders={{
            emptyMessage: 'No User Role are added. Please add User Role.',
            filterEmptyMessage: 'No matching results found',
            search: 'Search by user roles',
          }}
          filterPanelComponent={FilterUserRoles}
        />
        <Popup
          isOpen={isPopupOpen.isPopupOpen}
          title="Delete User Role"
          onClose={() => {
            setIsPopupOpen({ isPopupOpen: false, roleData: null })
          }}
        >
          <DeleteRolesPopup
            closePopUp={() => setIsPopupOpen({ isPopupOpen: false, roleData: null })}
            data={isPopupOpen.roleData}
          />
        </Popup>
      </>
    </MotionDiv>
  )
}

export default UserRoles
