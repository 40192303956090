import React from 'react';
import MotionDiv from 'libs/motionDiv';
import arrowRight from 'assets/icons/misc/arrowRight.svg';
import PurchaseOrder from 'assets/icons/order/PurchaseOrder.svg';
import SalesOrder from 'assets/icons/order/SalesOrder.svg';
import Card from 'libs/card/Card';
import { useNavigate } from 'react-router-dom';
import Chip from 'libs/chip/Chip';
import { useGetSalesOrdersStatsQuery } from './sales-order/sales-order-endpoints/salesOrders.endpoints';
import { selectSelectedItem } from 'store/redux/navSlice';
import { useSelector } from 'react-redux';
import FullPageLoader from 'libs/loader/FullPageLoader';
import useTabTitle from 'libs/hooks/useTabTitle';
import { useGetPurchaseOrdersStatsQuery } from './purchase-order/purchase-orders-endpoints/purchaseOrders.endpoints';
import { hasAccess } from 'utils/hasAccess';

// Define types for card details
interface OrderDetail {
  name: string;
  count: string; // Ensure this is always a string
}

interface CardDetail {
  icon: string;
  title: string;
  year: string;
  route: string;
  Order: OrderDetail[];
}

const Order: React.FC = () => {
  const plant = useSelector(selectSelectedItem);
  const purchaseOrderAccess = hasAccess('ord', { po: 'view' });
  const salesOrderAccess = hasAccess('ord', { so: 'view' });

  useTabTitle('Orders');

  const { data: SalesOrdersStats, isLoading: isSalesOrdersStatsLoading } = useGetSalesOrdersStatsQuery({
    plantId: plant?.id,
  });

  const { data: PurchaseOrdersStats, isLoading: isPurchaseOrdersStatsLoading } = useGetPurchaseOrdersStatsQuery({
    plantId: plant?.id,
  });

  // Convert count to string
  const cardDetails: CardDetail[] = [
    salesOrderAccess && {
      icon: SalesOrder,
      title: 'Sales Orders',
      year: 'FY 2024 - 2025',
      route: '/orders/sales-order',
      Order: [
        { name: 'IN_PROGRESS', count: (SalesOrdersStats?.IN_PROGRESS ?? '-').toString() },
        { name: 'CANCELLED', count: (SalesOrdersStats?.CANCELLED ?? '-').toString() },
        { name: 'COMPLETED', count: (SalesOrdersStats?.COMPLETED ?? '-').toString() },
        { name: 'NOT_STARTED', count: (SalesOrdersStats?.NOT_STARTED ?? '-').toString() },
      ],
    },
    purchaseOrderAccess && {
      icon: PurchaseOrder,
      title: 'Purchase Orders',
      year: 'FY 2024 - 2025',
      route: '/orders/purchase-order',
      Order: [
        { name: 'IN_PROGRESS', count: (PurchaseOrdersStats?.IN_PROGRESS ?? '-').toString() },
        { name: 'CANCELLED', count: (PurchaseOrdersStats?.CANCELLED ?? '-').toString() },
        { name: 'COMPLETED', count: (PurchaseOrdersStats?.COMPLETED ?? '-').toString() },
        { name: 'NOT_STARTED', count: (PurchaseOrdersStats?.NOT_STARTED ?? '-').toString() },
      ],
    },
  ].filter(Boolean) as CardDetail[];

  const navigate = useNavigate();

  return (
    <MotionDiv>
      <div>
        <h1 className="h4-bold-black">Orders</h1>
        <p className="p-sm-regular-1">Manage all purchase and sales orders in one place.</p>
        {isSalesOrdersStatsLoading || isPurchaseOrdersStatsLoading ? (
          <FullPageLoader />
        ) : (
          <div className="md:grid-flow-col-2 mt-2 grid gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4">
            {cardDetails.map((card) => (
              <Card
                key={card.title}
                title={card.title}
                subtitle={card.year}
                content={
                  <div className="grid grid-cols-2 gap-2 pt-4">
                    {card.Order.map((subCard) => (
                      <Chip
                        type="oval"
                        key={subCard.name}
                        title={subCard.name}
                        value={subCard.count} // This is now a string
                      />
                    ))}
                  </div>
                }
                rightIcon={<img src={arrowRight} alt="" style={{ height: '15px' }} />}
                leftIcon={<img src={card.icon} alt="" style={{ height: '24px' }} />}
                titleStyle="font-medium text-gray-800"
                subtitleStyle="text-gray-600 text-xs"
                onClick={() => {
                  localStorage.removeItem('PlanId');
                  navigate(card.route);
                }}
              />
            ))}
          </div>
        )}
      </div>
    </MotionDiv>
  );
};

export default Order;
