import { getToken } from 'utils/CheckAuth'
import { rootReducer } from 'store/services/root.service'
import { buildUrl } from 'utils/BuildUrl'

export const user = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    userInfo: builder.query({
      query: () => {
        return {
          url: `/user/me`,
          method: 'GET',
          headers: getToken(),
        }
      },
      transformResponse: (response: any) => {
        // Transform the response to the desired format
        return response[0]
        // const transformedResponse = response.data[0];
      },
      providesTags: ['UserInfo'],
    }),

    // Update user preferences
    updateUserPreferences: builder.mutation({
      query: ({ payload }) => {
        return {
          url: `/user/preferences`,
          method: 'PUT',
          headers: getToken(),
          body: payload,
        }
      },
      invalidatesTags: ['UserInfo'],
    }),

    getUsers: builder.query<any, { org_id: string; filters: any }>({
      query: ({ org_id, filters }) => {
        

        const URL = buildUrl(`/org/${org_id}/users`, filters)
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
        }
      },
      providesTags: ['UserInfo'],
    }),

    getUsersByPlant: builder.query<any, { plantId: string }>({
      query: ({ plantId }) => {
        return {
          url: `/plant/${plantId}/users`,
          method: 'GET',
          headers: getToken(),
        }
      },
      providesTags: ['UserInfo'],
    }),
    postUsers: builder.mutation({
      query: ({ data, org_id }) => {
        return {
          url: `/org/${org_id}/user/create`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['UserInfo'],
    }),
    putUsers: builder.mutation({
      query: ({ org_id, userId, data }) => {
        return {
          url: `/org/${org_id}/user/${userId}`,
          method: 'PUT',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['UserInfo'],
    }),
    putUserPreferences: builder.mutation({
      query: ({ data }) => {
        return {
          url: `/user/preferences`,
          method: 'PUT',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['UserInfo'],
    }),
    deleteUser: builder.mutation({
      query: ({ org_id, userId }) => {
        return {
          url: `/org/${org_id}/user/${userId}`,
          method: 'DELETE',
          headers: getToken(),
        }
      },
      invalidatesTags: ['UserInfo'],
    }),
    userStats: builder.query({
      query: ({ org_id }) => {
        return {
          url: `/org/${org_id}/users/count`,
          method: 'GET',
          headers: getToken(),
        }
      },
      providesTags: ['UserInfo'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetUsersQuery,
  usePutUsersMutation,
  useLazyUserInfoQuery,
  usePostUsersMutation,
  useUserInfoQuery,
  usePutUserPreferencesMutation,
  useUpdateUserPreferencesMutation,
  useDeleteUserMutation,
  useGetUsersByPlantQuery,
  useUserStatsQuery,
} = user
