import { FormikContextType } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { DatePicker } from 'rsuite'
import { DateRangePicker } from 'rsuite'
import { DateRange } from 'rsuite/esm/DateRangePicker'
import { getDateFormat, getPlaceholder } from 'utils/CurrentDateTime'
import MultiSelect from './components/MultiSelect'
import PhoneNumberInput from 'libs/phone-number-input/PhoneNumberInput'
import TimePicker from 'libs/time-picker/TimePicker'

interface DropdownOption {
  value: string
  label: string
  sublabel?: string
  disabled?: string
}
export interface FieldProps {
  formik: FormikContextType<any>
  name: string
  type?:
    | 'text'
    | 'textarea'
    | 'date'
    | 'number'
    | 'dateRange'
    | 'time'
    | 'select' // require options
    | 'multiselect' // require options
    | 'checkbox' // require options
    | 'radio' // require options
    | 'phonenumber'
    | 'timepicker'
  label?: string | number
  placeholder?: string
  required?: boolean
  style?: string
  options?: DropdownOption[]
  onChange?: (value: any) => void
  prefix?: string
  suffix?: string
  description?: string
  disabled?: boolean
  hideSearch?: boolean
  defaultValue?: any
  multiSelectPlaceholderTexts?: [string, string]
  shouldDisableFutureDate?: boolean
  shouldDisablePastDate?: boolean
  emptyError?: string
  itemType?: string
  width?: string
  fullWidth?: boolean
  hideborder?: boolean
  readOnly?: boolean
  alignText?: string
  title?: string
  onAdd?: () => void
  shouldAcceptDecimal?: boolean
}

/**
 * Parses a field name and returns either the field name itself or an object containing the parent field name, iteration number, and field name.
 *
 * @param {string} fieldName - The field name to parse.
 * @returns {(string | { parentFieldName: string; iterationNumber: number; fieldName: string })} - If the field name can be split into parts and the last part is a number, returns an object with the parent field name, iteration number, and field name. Otherwise, returns the field name itself.
 */
function parseFieldName(
  fieldName: string,
): string | { parentFieldName: string; iterationNumber: number; fieldName: string } {
  const parts = fieldName?.split('.')
  if (parts?.length === 1) {
    return fieldName
  } else {
    return {
      parentFieldName: parts[0],
      iterationNumber: parseInt(parts[1]),
      fieldName: parts[2],
    }
  }
}

const findIfError = (formik: any, name: string) => {
  const fieldName = parseFieldName(name)
  if (typeof fieldName === 'string') {
    return formik?.touched?.[fieldName] && formik.errors?.[fieldName]
  } else {
    return (
      formik?.touched?.[fieldName.parentFieldName]?.[fieldName.iterationNumber]?.[fieldName.fieldName] &&
      formik.errors?.[fieldName.parentFieldName]?.[fieldName.iterationNumber]?.[fieldName.fieldName]
    )
  }
}

const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.key === 'e' || e.key === 'E' || e.key === '+' || e.key === '-') {
    e.preventDefault()
  }
}

const handleMouseWheel = (event: React.WheelEvent<HTMLInputElement>) => {
  const eventTarget = event.target as HTMLInputElement
  eventTarget.blur()
}

const getDateFromAny = (value: Date | number) => {
  if (value instanceof Date) {
    return value
  }
  if (typeof value === 'number') {
    const stringifiedUnixDate = value.toString()
    if (stringifiedUnixDate.length === 10) {
      return new Date(value * 1000)
    } else if (stringifiedUnixDate.length > 10) {
      const substring = stringifiedUnixDate.substring(0, 10)
      return new Date(parseInt(substring) * 1000)
    } else {
      return null
    }
  }
}

const getDateRangeFromAny: (value: any) => DateRange | null | undefined = (value) => {
  if (Array.isArray(value) && value.length === 2) {
    const startDate = getDateFromAny(value[0])
    const endDate = getDateFromAny(value[1])
    if (startDate == null || endDate == null) return null

    return [startDate, endDate]
  }
}

const disabledFutureDates = (date: Date) => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const currentDate = new Date(date)
  currentDate.setHours(0, 0, 0, 0)
  return date && currentDate > today
}

const disabledPastDates = (date: Date) => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const currentDate = new Date(date)
  currentDate.setHours(0, 0, 0, 0)
  return date && currentDate < today
}

const Field = (props: FieldProps) => {
  const {
    type,
    formik,
    name,
    placeholder = '',
    label,
    required = false,
    style,
    options,
    prefix,
    suffix,
    description,
    disabled = false,
    defaultValue = '',
    shouldDisableFutureDate = false,
    shouldDisablePastDate = false,
    width,
    hideborder = false,
    readOnly = false,
    alignText,
    title,
    onAdd,
  } = props

  const hasError = findIfError(formik, name)

  const borderColorClass = hasError ? 'border-error' : hideborder ? 'border-none' : 'border-border'

  const ifSuffix = 'rounded-tr-none rounded-br-none !important'

  const className = ` h-[36px] w-full placeholder:text-gray-500  p-2 pb-2  text-sm outline-none p-xs-regular  rounded-l rounded-r
   ${!hideborder && ' border hover:border-primary  ring-primary active:ring-2 ring-opacity-30 '} 
   ${readOnly ? 'cursor-default text-disabled' : ''}
   disabled:cursor-default disabled:opacity-50
  ${borderColorClass}  ${style} ${suffix && ifSuffix} ${hideborder ? '' : ' bg-white'} 
  ${alignText && ` text-${alignText}`}`

  const fdate = getDateFromAny(formik?.values[name])

  const rangeDate = useMemo(() => getDateRangeFromAny(formik?.values[name]), [formik?.values[name]])
  const [dateRange, setDateRange] = useState<DateRange | null | undefined>(rangeDate)
  const [time, setTime] = useState(fdate)

  useEffect(() => {
    setDateRange(rangeDate)
  }, [rangeDate])

  const formikProps = formik?.getFieldProps(name)

  if (formikProps?.value && typeof formikProps.value === 'string') {
    formikProps.value = formikProps.value.startsWith(' ') ? formikProps.value.trimStart() : formikProps.value
  }

  const { onBlur, onChange, ...restProps } = formikProps

  return (
    <form
      data-testid="field-wrapper"
      autoComplete="off"
      onSubmit={formik.handleSubmit}
      className={`mt-2 flex ${width ? `w-[${width}]` : 'w-full'} flex-col`}
    >
      {/* label */}
      {label && (
        <span data-testid="label" className={` mb-1 p-sm-regular ${disabled && 'text-disabled  '}`}>
          {label ?? ''}
          {required && (
            <span data-testid="asterisk" className={disabled ? 'text-disabled' : 'text-red '}>
              *
            </span>
          )}
        </span>
      )}

      {/* field type - text */}
      {(type === 'text' || type == undefined) && (
        <div className="flex flex-row ">
          {prefix && (
            <div
              className={`flex  ${disabled && 'opacity-40'}  items-center justify-center rounded-l bg-[#434C52] px-1 text-[#F8FCFF] p-xs-regular-dim1`}
            >
              {prefix}
            </div>
          )}

          <input
            // style={{ backgroundColor: 'red' }}
            data-testid="field"
            id={name}
            autoComplete="off"
            type="text"
            className={className + `${prefix ? ' rounded-l-none ' : '  '}`}
            placeholder={placeholder}
            {...restProps}
            onChange={onChange}
            defaultValue={defaultValue}
            readOnly={readOnly}
          />
          {suffix && (
            <div
              className={`flex  ${disabled && 'opacity-40'}  items-center justify-center rounded-r bg-[#434C52] px-1 text-[#F8FCFF] p-xs-regular-dim1`}
            >
              {suffix}
            </div>
          )}
        </div>
      )}

      {/* field type - number */}
      {type === 'number' && (
        <div className="flex flex-row">
          <input
            id={name}
            data-testid="field"
            autoComplete="new-password"
            autoCorrect="off"
            type="number"
            className={className}
            placeholder={placeholder}
            onKeyDown={handleKeyPress}
            onWheel={handleMouseWheel}
            defaultValue={defaultValue}
            disabled={disabled}
            {...restProps}
            onChange={onChange}
            value={restProps.value == null ? '' : restProps.value}
          />
          {suffix && (
            <div
              className={`flex flex-shrink-0 px-2 ${disabled && 'opacity-40'} items-center justify-center truncate rounded-r bg-[#434C52] text-[#F8FCFF] p-xs-regular-dim1`}
            >
              {suffix}
            </div>
          )}
        </div>
      )}

      {/* field type - radio */}
      {type === 'radio' && (
        <>
          <div className="mx-auto flex w-5">
            {suffix && (
              <div className="flex min-w-12 items-center justify-center rounded-r bg-[#434C52] text-[#F8FCFF] p-xs-regular-dim1">
                {suffix}
              </div>
            )}
          </div>

          <div className="flex flex-row gap-4 ">
            {options?.map((option) => {
              return (
                <div
                  key={option.value}
                  onClick={() => {
                    formik.setFieldValue(name, option.value)
                  }}
                  className="flex cursor-pointer flex-row items-center gap-1 "
                >
                  <div
                    className={`h-4 w-4 rounded-full border${formik.values?.[name] === option.value ? '-4' : ''}  box-border flex items-center justify-center border-primary`}
                  />
                  <span className="mt-[2px] text-center text-gray-500 p-xs-regular-dim1 ">{option.label}</span>
                </div>
              )
            })}
          </div>
        </>
      )}

      {/* field type - textarea */}
      {type === 'textarea' && (
        <div className="">
          <textarea
            rows={3}
            id={name}
            className={className}
            placeholder={placeholder}
            {...formik?.getFieldProps(name)}
            style={{ height: '50px' }}
          />
        </div>
      )}

      {/* field type - checkbox */}
      {type === 'checkbox' && (
        <div className=" flex items-center ">
          <input
            id={name}
            type="checkbox"
            className="mr-2"
            checked={formik.values[name]}
            onChange={(e) => formik.setFieldValue(name, e.target.checked)}
            disabled={disabled}
          />
          <label className="p-sm-regular">{label}</label>
        </div>
      )}

      {/* field type - date */}
      {type === 'date' && (
        <div className="">
          <DatePicker
            data-testid="field"
            style={{
              width: '100%',
              height: '36px',
            }}
            className={`${className} no-padding active:ring-2 `}
            placement="auto"
            format={getDateFormat()}
            placeholder={getPlaceholder()}
            oneTap={true}
            shouldDisableDate={
              shouldDisableFutureDate
                ? shouldDisablePastDate
                  ? (date) => disabledFutureDates(date) || disabledPastDates(date)
                  : disabledFutureDates
                : shouldDisablePastDate
                  ? disabledPastDates
                  : undefined
            }
            onChange={(date) => {
              let unixDate = null
              if (date) {
                date.setHours(0, 0, 0, 0)
                unixDate = date ? Math.floor(date.getTime() / 1000) : null
              }
              formik.setFieldValue(name, unixDate)
            }}
            value={formik.values[name] == null ? null : new Date(formik.values[name] * 1000)}
            editable={false}
            cleanable={true}
            onClean={() => {
              formik.setFieldValue(name, null)
            }}
          />
        </div>
      )}

      {/* field type - dateRange */}
      {type === 'dateRange' && (
        <div className="">
          <DateRangePicker
            style={{
              width: '100%',
              height: '36px',
            }}
            className={`${className}  no-padding active:ring-2`}
            value={dateRange}
            onChange={(date) => {
              setDateRange(date)
              let startDate = null
              let endDate = null
              if (date) {
                // Set startDate to 00:00:00
                date[0].setHours(0, 0, 0, 0)
                startDate = Math.floor(date[0].getTime() / 1000)

                // Set endDate to 23:59:59
                date[1].setHours(23, 59, 59, 999)
                endDate = Math.floor(date[1].getTime() / 1000)
              }
              formik.setFieldValue(name, [startDate, endDate])
            }}
            placement="auto"
            format={getDateFormat()}
            placeholder="Select your date range"
            shouldDisableDate={shouldDisableFutureDate ? disabledFutureDates : undefined}
            cleanable={true}
            onClean={() => {
              formik.setFieldValue(name, null)
            }}
          />
        </div>
      )}

      {/* field type - time */}
      {type === 'time' && (
        <div className="pb-1">
          <DatePicker
            style={{
              width: '100%',
              height: '32px',
            }}
            placement="auto"
            placeholder="Select time"
            format="HH:mm:ss"
            onChange={(date) => {
              setTime(date)
              const unixDate = date ? Math.floor(date.getTime() / 1000) : null
              formik.setFieldValue(name, unixDate)
            }}
            value={time}
          />
        </div>
      )}

      {/* field type - multiselect */}
      {(type === 'multiselect' || type === 'select') && (
        <MultiSelect restProps={restProps} title={title} onAdd={onAdd} className={className} {...props} />
      )}

      {type === 'phonenumber' && <PhoneNumberInput restProps={restProps} {...props} />}
      {type === 'timepicker' && <TimePicker name={name} formik={formik} />}

      {/* field type - description */}
      {description && (
        <p className={`flex pt-1 text-xs text-gray-500  ${disabled && 'text-disabled'}`}>{description}</p>
      )}

      {/* field type - error message */}
      {hasError && <p className="pt-1 text-xs font-normal text-error">{hasError?.toString()}</p>}
    </form>
  )
}

export default Field
