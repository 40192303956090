import Button from 'libs/button/Button'
import Chip from 'libs/chip/Chip'
import WhiteCard from 'libs/card/WhiteCard'
import MotionDiv from 'libs/motionDiv'
import PaginatedTable from 'libs/table/PaginatedTable'

import PlusIcon from 'assets/icons/camera-page/PlusIcon'
import { useEffect, useState } from 'react'

import { formatDate } from 'utils/unixConverter'

import QuickAddEditSalesOrder from 'libs/quick-add-edits/quick-add-edit-so/QuickAddEditSoPanel'

import { useNavigate } from 'react-router-dom'
import { hasAccess } from 'utils/hasAccess'

const ViewSalesOrders = ({ customersData, setPage, loading }: any) => {
  const customer = customersData?.data
  const customerAccess = hasAccess('con', { cus: 'e' })
  const salesOrderAccess = hasAccess('ord', { so: 'e' })

  const navigate = useNavigate()
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const [selectedPurchaseOrder, setSelectedSalesOrder] = useState<any>(null)

  useEffect(() => {
    setPage(3)
  }, [])

  const columns = [
    {
      Header: 'Sales Order ID',
      accessor: 'salesOrderID',
      disableSortBy: true,
      width: 150,
      Cell: ({ row }: any) => {
        const customer = row?.original

        return (
          <div
            className="hyperlink"
            onClick={() => {
              //  navigate(`/contacts/vendor-management/${vendor?._id}/view-basic-details`)
              salesOrderAccess && navigate(`/orders/sales-order/view/${customer?._id}`)
            }}
          >
            {customer?.salesOrderID}
          </div>
        )
      },
    },
    {
      Header: 'Product',
      accessor: 'products',
      disableSortBy: true,
      width: 150,
      Cell: ({ row }: any) => {
        const product = row.original?.products?.length

        return (
          <div
            className="hyperlink"
            onClick={() => {
              salesOrderAccess && navigate(`/orders/sales-order/view/${row?.original?._id}`)
            }}
          >
            <span>{product} Raw Materials</span>
          </div>
        )
      },
    },

    {
      Header: <div className="flex w-full items-center justify-end">Delivery Date</div>,
      accessor: 'deliveryDate',
      disableSortBy: true,
      width: 100,
      Cell: ({ row }: any) => {
        const formattedDate = formatDate(row.original?.deliveryDate)

        return (
          <div className="flex w-full justify-between">
            <span>{formattedDate}</span>
          </div>
        )
      },
    },

    {
      Header: <div className="flex w-full items-center justify-end">Progress</div>,
      accessor: 'status',
      width: 50,
      Cell: ({ row }: any) => <Chip title={row?.original?.status} />,
    },
  ]

  return (
    <MotionDiv>
      <WhiteCard className=" flex min-h-[70vh] flex-col gap-6">
        <div className="flex w-full justify-between">
          <h5 className="h5">Sales Orders</h5>
          {customerAccess && (
            <div>
              <Button
                startIcon={<PlusIcon />}
                color="primary"
                onClick={() => {
                  setIsPanelOpen(true)
                  setSelectedSalesOrder(null)
                }}
              >
                {'Add Sales Order'}
              </Button>
            </div>
          )}
        </div>
        <QuickAddEditSalesOrder
          id={selectedPurchaseOrder?.purchaseOrderID?._id}
          onClose={() => setIsPanelOpen(false)}
          isOpen={isPanelOpen}
        />{' '}
        <div className="h-screen overflow-y-auto">
          <PaginatedTable
            columns={columns}
            loading={loading}
            rows={customer[0]?.salesOrder}
            hidePagination={true}
            emptyMessage={'No sales order found. Please add new sales order'}
          />
        </div>
      </WhiteCard>
    </MotionDiv>
  )
}

export default ViewSalesOrders
