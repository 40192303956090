import WhiteCard from 'libs/card/WhiteCard'
import Button from 'libs/button/Button'

import MotionDiv from 'libs/motionDiv'

import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { formatString } from 'utils/trimStringValues'
import { hasAccess } from 'utils/hasAccess'

const ViewPersonalInformation = ({ employeeData, setPage }: any) => {
  const Employee = employeeData?.data?.[0]
  const employeeDirectoryAccess = hasAccess('emp', { ed: 'e' })

  const { id } = useParams()

  const navigate = useNavigate()

  useEffect(() => {
    setPage(1)
  }, [])

  const employeeInfo = [
    { label: 'Employee ID', key: 'employeeID' },
    { label: 'Email', key: 'email' },
    { label: 'Employee Name', key: 'employeeName' },
    { label: 'Contact Number', key: 'contactNumber' },
    { label: 'Gender', key: 'gender' },
    { label: 'Emergency Contact Name', key: 'emergencyContactName' },
    { label: 'Government ID', key: 'governmentID' },
    { label: 'Emergency Contact Number', key: 'emergencyContactNumber' },
  ]

  return (
    <MotionDiv>
      <WhiteCard className=" flex min-h-[70vh] flex-col gap-6">
        <div className="flex w-full justify-between">
          <h5 className="h5">Personal Information</h5>
          {employeeDirectoryAccess && (
            <div>
              <Button
                color="primary"
                onClick={() => {
                  navigate(`/employee/employee-management/${id}/edit/personal-information`)
                }}
              >
                Edit Employee Details
              </Button>
            </div>
          )}
        </div>
        <div className="flex  max-w-[800px] justify-between">
          <div className="grid grid-cols-4 gap-4 text-xs">
            {employeeInfo.map((field, fieldIndex) => (
              <React.Fragment key={fieldIndex}>
                <span className="text-gray-500">{field.label}</span>
                {field.key === 'gender' ? formatString(Employee?.[field.key] || '-') : Employee?.[field.key] || '-'}
              </React.Fragment>
            ))}
          </div>
        </div>
      </WhiteCard>
    </MotionDiv>
  )
}

export default ViewPersonalInformation
