import useTabTitle from 'libs/hooks/useTabTitle'
import MotionDiv from 'libs/motionDiv'

import FilterableSearchTable from 'libs/table/filterable-search-table/FilterableSearchTable'
import { t } from 'i18next'
import Pen from 'assets/icons/camera-page/Pen'
import Bin from 'assets/icons/camera-page/Bin'
import { useMemo, useState } from 'react'
import Popup from 'libs/popup/Popup'
import FilterMaintenanceRequest from './panel/MaintenanceRequestFilter'
import { useNavigate } from 'react-router-dom'
import Button from 'libs/button/Button'
import { useGetMaintenanceRequestsQuery } from './maintenance-request/maintenance-request'

import Chip from 'libs/chip/Chip'

import DeletePopUp from './popup/DeletePopup'
import { formatUnixDateWithTime } from 'utils/unixConverter'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import RejectMaintenanceRequestPopup from './popup/RejectedMaintenanceRequest'

const additionalFilters = {
  machines: [],
  requestID: [],
  page_no: 1,
  page_size: 10,

  sortBy: 'created_on',
  sortDir: 'DESC',
}
interface State {
  state: boolean
  ids: string[]
}

const MaintenanceRequest = () => {
  useTabTitle('Maintenance Request')
  const plant = useSelector(selectSelectedItem)
  const [isRejectPopup, setIsRejectPopup] = useState<State>({
    state: false,
    ids: [],
  })
  const navigate = useNavigate()
  const [deleteState, setDeleteState] = useState<State>({
    state: false,
    ids: [],
  })
  const [batchData, setBatchData] = useState<string[]>([])

  const toggleDeletePopUp = (data: any) => {
    if (!deleteState.state) {
      setDeleteState({ state: true, ids: data?._id })
    } else {
      setDeleteState({ state: false, ids: [] })
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Request ID',
        accessor: 'requestID',
        width: 150,

        Cell: ({ row }: any) => {
          const request = row?.original
          return (
            <div
              className="hyperlink"
              onClick={() => {
                navigate(`/maintenance/maintenance-request/${request?._id}/view`)
              }}
            >
              {request?.requestID}
            </div>
          )
        },
      },
      {
        Header: 'Reason',
        accessor: 'reason',
        width: 100,
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: 200,
      },
      {
        Header: 'Machines',
        accessor: 'machine',
        width: 10,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 10,
        Cell: ({ row }: any) => {
          const orderStatus = row?.original?.status
          return (
            <div>
              <Chip title={orderStatus} />
            </div>
          )
        },
      },

      {
        Header: 'Created by',
        accessor: 'created_by',
        width: 10,

        Cell: ({ row }: any) => {
          const created_by = row.original.created_by
          if (created_by) {
            const name = `${created_by?.first_name} ${created_by?.last_name}`
            const time = formatUnixDateWithTime(row.original.created_on, plant?.timezone)
            return (
              <div>
                <div>{name}</div>
                <div className="p-xs-regular-dim">{time}</div>
              </div>
            )
          }
        },
      },

      {
        Header: 'Actions',
        accessor: 'actions',
        width: 100,
        Cell: ({ row }: any) => (
          <div className="flex w-full items-center justify-between ">
            <div className="flex flex-row gap-4">
              <button
                className={`${row?.original?.is_order === false ? '' : 'opacity-40'}`}
                onClick={
                  row?.original?.is_order === false
                    ? (event: { stopPropagation: () => void }) => {
                        event.stopPropagation()
                        navigate(`/maintenance/maintenance-request/${row?.original?._id}/edit-record`)
                      }
                    : undefined
                }
              >
                <Pen />
              </button>{' '}
              <button
                className={`${row?.original?.is_order === false ? '' : 'opacity-40'}`}
                onClick={
                  row?.original?.is_order === false
                    ? (event: { stopPropagation: () => void }) => {
                        event.stopPropagation()
                        setDeleteState({ state: true, ids: row?.original?._id })
                      }
                    : undefined
                }
              >
                <Bin />
              </button>
            </div>
          </div>
        ),
        disableSortBy: true,
      },
    ],
    [],
  )
  const handleSelectAllRows = (data: any) => {
    const entryIds = data
      ?.filter((entry: any) => entry?.original?.is_order === false)
      .map((entry: any) => entry.original?._id)

    setBatchData(entryIds)

    if (entryIds.length === batchData.length) {
      setBatchData([])
    }
  }
  const toggleRejectPopUp = (data: any) => {
    if (!isRejectPopup.state) {
      setIsRejectPopup({ state: true, ids: [data?._id] })
    } else {
      setIsRejectPopup({ state: false, ids: [] })
    }
  }
  const handleAddRequest = () => {
    navigate('add/request')
  }
  const handleCheckboxClick = (data: any) => {
    const entryId = data?._id
    const isChecked = batchData.includes(entryId)
    if (isChecked) {
      setBatchData((prev) => {
        return prev.filter((id) => id !== entryId)
      })
    } else {
      setBatchData((prev) => [...prev, entryId])
    }
  }
  const handleEntrySelection = (row: any) => {
    if (row?.is_order === false) {
      handleCheckboxClick(row) // Trigger checkbox click on row click
    }
  }

  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black">Maintenance Requests</h1>
        <div className="flex justify-between">
          {' '}
          <p className=" p-sm-regular-1">
            {' '}
            Quickly report and manage maintenance issues to ensure the seamless operation and safety of all equipment
            and assets.
          </p>
        </div>
        <FilterableSearchTable
          useQuery={useGetMaintenanceRequestsQuery}
          columns={columns}
          addButton={{ label: 'Add Maintenance Request', onClick: handleAddRequest }}
          placeholders={{
            emptyMessage: 'No requests are added. Please add requests.',
            filterEmptyMessage: 'No matching results found',
            search: 'Search Requests by ID, Reason, or Machines',
          }}
          filterPanelComponent={FilterMaintenanceRequest}
          additionalFilters={additionalFilters}
          showCheckbox={true}
          batchData={batchData}
          onSelectAll={handleSelectAllRows}
          onSingleClick={handleEntrySelection}
        />
        {batchData.length != 0 && (
          <div className="fixed bottom-0 left-[92px]  flex h-16 w-full items-center justify-between gap-4 bg-white">
            <div className="flex gap-2">
              <div className="mt-1 pl-8 p-sm-regular">{/* {NumberFormat(batchData?.length)} {t('selected')} */}</div>

              <Button
                className={`cursor-not-allowed'} text-black opacity-40 shadow-sm p-xs-regular  btn-primary`}

                // className={`shadow-sm p-xs-regular btn-primary ${batchData?.length > 0 ? '' : 'cursor-default'}`}
              >
                Create Maintenance Order
              </Button>
              <Button
                className={`cursor-not-allowed'} text-black opacity-40 shadow-sm p-xs-regular  btn-primary`}
                // className={`shadow-sm p-xs-regular btn-primary ${batchData?.length > 0 ? '' : 'cursor-default'}`}
              >
                Link to Order
              </Button>
              <Popup
                isOpen={deleteState.state}
                title="Delete Maintenance Requests"
                onClose={() => {
                  setDeleteState({ state: false, ids: [] })
                }}
              >
                <DeletePopUp
                  closePopUp={toggleDeletePopUp}
                  deleteState={deleteState}
                  batchData={batchData}
                  setBatchData={setBatchData}
                  setDeleteState={setDeleteState}
                />
              </Popup>
              <Button startIcon={<Bin />} color="error" onClick={toggleDeletePopUp}>
                {t('delete_selected')}
              </Button>
              <Popup
                isOpen={isRejectPopup.state}
                title="Reject Maintenace Requests?"
                onClose={() => {
                  setIsRejectPopup({ state: false, ids: [] })
                }}
              >
                <RejectMaintenanceRequestPopup
                  closePopUp={toggleRejectPopUp}
                  isRejectPopup={isRejectPopup}
                  batchData={batchData}
                  setBatchData={setBatchData}
                />
              </Popup>
            </div>
            <div className="pr-28">
              <Button onClick={toggleRejectPopUp} color="error" disabled={batchData?.length === 0}>
                Reject Selected
              </Button>
            </div>
          </div>
        )}
      </>
    </MotionDiv>
  )
}

export default MaintenanceRequest
