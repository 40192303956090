import React from 'react'
import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import { useGetUsersByPlantQuery } from 'pages/settings/user-management/users/user-endpoints/user.endpoint'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'

interface Props {
  closePanel: () => void
  setFilters: (filters: any) => void
  filters: Filters
}

interface Filters {
  machines: string[]
  createdBy: string[]

  createdBetween: number[]
  status: string[]
}
interface FormValues {
  machines: string[]
  status: string[]
  createdBy: string[]

  createdBetween: number[]
}

const FilterMaintenanceRequest: React.FC<Props> = ({ closePanel, setFilters, filters }: Props) => {
  const initialValues: FormValues = {
    machines: filters.machines ?? [],
    status: filters.status ?? [],
    createdBy: filters.createdBy ?? [],
    createdBetween: filters?.createdBetween?.length == 0 ? [] : filters?.createdBetween,
  }
  const plantId = useSelector(selectSelectedItem)
  const { data: users } = useGetUsersByPlantQuery(
    {
      plantId: plantId?.id,
    },
    { skip: !plantId?.id },
  )

  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      searchText: '',
      category: [],
      status: [],
      CreatedBy: [],
      createdBetween: [],
      sortBy: 'created_on',
      sortDir: 'DESC',
    })

    closePanel()
  }

  const filterFormik = useFormik<FormValues>({
    initialValues: initialValues,
    onSubmit: (values) => {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        createdBetween: values.createdBetween == null ? [] : values.createdBetween,
        createdBy: values.createdBy,
        machines: values.machines,
        status: values.status,
      }))
    },
  })

  const category = [
    {
      name: 'Requested',
      value: 'REQUESTED',
      _id: '101',
    },
    {
      name: 'Closed',
      value: 'CLOSED',
      _id: '102',
    },
    {
      name: 'Rejected',
      value: 'REJECTED',
      _id: '103',
    },
    {
      name: 'Processing',
      value: 'PROCESSING',
      _id: '104',
    },
  ]
  

  const categoryOptions = category.map((item) => ({
    label: item.name,
    value: item.value,
    sublabel: '',
    disabled: '',
  }))

  

  const usersOption = users?.map((user: any) => ({
    _id: user._id,
    label: user.first_name + ' ' + user.last_name,
    sublabel: user.phone_number,
    value: user?._id,
  }))

  return (
    <>
      <div className="flex flex-col gap-3">
        <Field
          type="multiselect"
          label="Machines"
          placeholder="Select Machine"
          multiSelectPlaceholderTexts={['Machines', 'Machines']}
          options={categoryOptions}
          formik={filterFormik}
          name="machines"
          disabled
        />
        {/* Status */}
        <Field
          type="multiselect"
          label="Status"
          placeholder="Select Status"
          options={categoryOptions}
          formik={filterFormik}
          name="status"
        />
        <Field
          type="multiselect"
          label="Created By"
          placeholder="Select Created By"
          options={usersOption}
          formik={filterFormik}
          name="createdBy"
        />
        {/* Created Date Range */}
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Date Created"
          formik={filterFormik}
          name="createdBetween"
        />
      </div>
      <div className="flex justify-between pt-5 ">
        <div>
          <Button
            onClick={() => {
              handleReset()
            }}
          >
            Reset
          </Button>
        </div>
        <div className="flex gap-2">
          <Button onClick={closePanel}>Cancel</Button>

          <Button
            color="primary"
            type="submit"
            onClick={() => {
              filterFormik.handleSubmit()
              closePanel()
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  )
}

export default FilterMaintenanceRequest
